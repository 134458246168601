/* CitySelectionPopup.css */
.city-selection-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Semi-transparent background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.city-selection-popup {
    width: 90%;
    max-width: 1119px;
    /* Adjust the maximum width as needed */
    min-height: 367px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.detect {
    color: #959595;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 90%;
    display: flex;
    align-items: center;
    margin-top: 16px;
    padding-bottom: 22px;
    padding-left: 16px;
    border-bottom: 1px solid #959595;
    gap: 8px;
    cursor: pointer;

}

.all-city {
    display: flex;
    /* margin-top: 34px; */
    flex-wrap: wrap;

}

.cities {
    display: flex;
    flex-direction: column;
    color: #EFEFEF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 200% */
    display: flex;
    width: 158px;
    height: 148px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
}

.cities2 {
    display: none;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    color: #EFEFEF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 200% */
    justify-content: center;
    align-items: center;
}
.city-selection-popup {
    background-image: url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\bobleft.svg'), url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\bobright.svg');
    background-repeat: no-repeat;
    background-position: top left, right top;
    background-blend-mode: overlay;


  }
  .cities:hover{
    background-image: url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\cityhover.svg');
    background-repeat: no-repeat;
    background-position: center;
  }


@media (max-width:1053px) {
    /* .all-city img {
        display: none;
    } */

    .all-city {
        gap: 24px;
        justify-content: center;

    }

    .cities {
        height: 100px;
        width: 100px;
        /* border: 1px solid #FEFEFE; */

        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }


}
@media (max-width:383px) {
     .all-city img {
        display: none;
    }
    .cities{
        color:  #FEFEFE;
text-align: center;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 200% */
display: flex;
height: 48px;
padding: 8px 0px;
justify-content: center;
align-items: center;
flex-shrink: 0;
    }
    .all-city {
        gap: 8px;
        justify-content: center;
    }
}