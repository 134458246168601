.general-section {
    padding-top: 60px;
    background-color: var(--primary-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.general-section {
    background-image: url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\1.svg'), url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\3.svg');
    background-repeat: no-repeat;
    background-position: top left, right bottom;
    width: 100%;
  }

.general-heading {
    color: #FEFEFE;
    font-family: var(--font-montserrat);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;

}

.general-line {
    display: block;
    margin: 0 auto;
    /* Center the line image horizontally */
    padding-bottom: 48px;
}

.about-heading {
    max-width: 736px;
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
}

.about-para {
    color: #FEFEFE;
    text-align: center;

    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    max-width: 1120px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.our-mission {
    max-width: 1011px;
    border-radius: 8px 0px 0px 8px;
    background: #1F262D;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 48px;
    margin-bottom: 68px;
}

.our-mission h2 {
    color: #FEFEFE;
    margin-left: 78px;

    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
}

.our-mission p {
    color: #FEFEFE;
    margin-top: 24px;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    justify-content: center;
    text-align: justify;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 24px;


}

.for-right {
    width: 100%;
    display: flex;
    justify-content: end;
}

.for-left {
    width: 100%;
    display: flex;
    justify-content: start;
}

.whyus {
    max-width: 1011px;
    border-radius: 0px 8px 8px 0px;
    background: #1F262D;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 131px;
    padding-bottom: 35px;

}

.whyus h2 {
    color: #FEFEFE;
    margin-left: 78px;

    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
}

.whyus p {
    color: #FEFEFE;
    margin-top: 24px;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    justify-content: center;
    text-align: start;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 32px;
    margin-bottom: 0px;
    display: flow;

}
.about-para-p{
    font-size: 20px;
}

@media (max-width:1079px) {
    .about-para-p{
        width: 84%;
    }
}

@media (max-width:500px) {
    .our-mission{
        justify-content: center;
        align-items: center;
    }
    .our-mission h2{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }
    .our-mission p{
        margin-left: 20px;
        margin-right: 20px;
    }
    .whyus{
        justify-content: center;
        align-items: center;
    }
    .whyus h2{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }
    .whyus p{
        margin-left: 20px;
        margin-right: 20px;
    }

    .about-heading{
        font-size: 28px;
    }
    .about-para-p{
        font-size: 18px;
    }
}