.profile-bar {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    gap: 9px;
    justify-content: center;

}

.profile-button1 {
    width: 128px;
    height: 41px;
    flex-shrink: 0;
    background-color: #1F262D;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 14px;
    border-radius: 4px;

}

.profile-button2 {
    width: 155px;
    height: 41px;
    flex-shrink: 0;
    background-color: #1F262D;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 14px;
    border-radius: 4px;

}

.profile-button3 {
    width: 155px;
    height: 41px;
    flex-shrink: 0;
    background-color: #1F262D;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 14px;
    border-radius: 4px;

}

.profile-button4 {
    width: 208px;
    height: 41px;
    flex-shrink: 0;
    background-color: #1F262D;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 14px;
    border-radius: 4px;

}

.profile-button5 {
    width: 208px;
    height: 41px;
    flex-shrink: 0;
    background-color: #1F262D;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 14px;
    border-radius: 4px;

}
.profile-bar div{
    gap: 14.45px;
    cursor: pointer;
}
.active4{
    background-color:  #FEFEFE !important;
    color:  #0E1111;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.hovered{
    background-color:  #FEFEFE !important;
    color:  #0E1111;
}


@media (max-width: 900px) {
    .profile-button {
        flex: 1 0 27.33%;
        /* 3 buttons in each row */
    }
}

/* Media query for a single row with sliding navigation */
@media (max-width: 450px) {
    .profile-bar {
        flex-wrap: nowrap;
        /* Prevent wrapping */
        overflow-x: auto;
        /* Enable horizontal scrolling */
        width: 100%;
        justify-content: unset;
    }

    .profile-button1,
    .profile-button2,
    .profile-button3,
    .profile-button4,
    .profile-button5
     {
        flex: 0 0 auto;
    }
}