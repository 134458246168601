.cover-div input{
    padding-left: 16px;
    box-sizing: border-box;
}
.date-pic-dob{
    width: 280px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: #262626;
    color: #959595;
    /* padding-left: 16px; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.root-98 div{

}

.edit-date{
    width: 280px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: var(--primary-black);
    color: #959595;
    padding-left: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .root-98 div{
        background: var(--primary-black);

    }
    
}