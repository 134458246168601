.main-div {
    background-color: var(--primary-black);
    margin-left: 32px;
    margin-right: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 786px;

}

.cover-div {
    max-width: 784px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-name {
    width: 100%;
    height: 100%px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;

}

.profile-image {
    width: 141px;
    height: 158px;
    background-color: var(--Dark-blue);
    border: 1px solid #FEFEFE;
    max-width: 100%; /* Ensure the image does not exceed the container's width */
    max-height: 100%; /* Ensure the image does not exceed the container's height */
    display: block; /* Make the image a block-level element to remove any extra space */
    margin: auto;
    object-fit: cover;
}

.profile-image img {
    height: 100%;
    width: 100%;
}

.profile-detail {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding-left: 30px;
    padding-top: 26px;
    padding-bottom: 14px;
    background-color: var(--Dark-blue);
    width: 82%;
    border: 1px solid #FEFEFE;
    min-height: 120px;

}

.user-name {
    color: #FFF;

    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    /* 128.571% */
    margin-top: 0px;
    margin-bottom: 12px;
}

.email-id {
    color: #FFF;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    /* 225% */
    margin-top: 0px;
    margin-bottom: 0px;
    height: fit-content;
}

.number {
    color: #FFF;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.personal-detail {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    background-color: var(--Dark-blue);
    margin-bottom: 24px;
    padding-bottom: 36px;
    gap: 36px;
    border: 1px solid #FEFEFE;

}

.address-detail {
    max-width: 784px;
    flex-shrink: 0;
    background-color: var(--Dark-blue);
    margin-bottom: 121px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border: 1px solid #FEFEFE;
    width: 100%;

}

.detail-heading {
    color: #FEFEFE;

    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    /* 150% */
}

.lastname input,
.firstname input {
    width: 280px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    color: #959595;

    /* Body 1 */
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;
}

.address1 input,
.address2 input,
.landmark input {
    width: 74%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    color: #959595;

    /* Body 1 */
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;
}

.names1 {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.dob {
    display: flex;
    width: 74%;
    flex-direction: column;
}

.dob input {
    width: 280px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: #262626;
    color: #959595;

}

.top-head {
    display: flex;
    width: 74%;
    justify-content: space-between;
}

.top-head div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-white);
    font-size: 16px;
}

.address1 {
    width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.address2 {
    width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.landmark {
    width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.town-city {
    width: 75%;
    display: flex;
    display: flex;
    flex-direction: column;
}

.State {
    width: 75%;
    display: flex;
    display: flex;
    flex-direction: column;
    margin-bottom: 68px;
}

.town-city input {
    width: 74%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
}

.city-child3 {
    position: relative;
    right: 30px;
}

.town-city select,
.State input {
    width: 280px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    color: var(--primary-white);
    padding-left: 8px;
    color: #959595;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;

}

select:hover {
    color: var(--primary-white);
    background-color: #262626;
}

select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    /* Default background color */
    color: #000;
    /* Default text color */
    cursor: pointer;
}

.gender {
    width: 75%;
    display: flex;
    flex-direction: column;

}

.gender select {
    width: 280px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    color: #959595;

    /* Body 1 */
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;
    padding-left: 8px;
}

.general-name {
    display: flex;
    color: #959595;

    /* Label */
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 120% */
    letter-spacing: 0.15px;
    padding-left: 9px;
    padding-top: 5px;

}

.address-name {
    display: flex;
    color: #959595;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 120% */
    letter-spacing: 0.15px;
    padding-left: 9px;
    padding-top: 7px;
    width: 75%;
}

.dob-gender {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.profile-image-container {
    position: relative;
    display: inline-block;
    width: 160px;
    height: 160px;
    overflow: hidden;
}


.profile-image {
    max-width: 100%;
    transition: opacity 0.2s linear;
    /* Add opacity transition */
}

.profile-image-upload {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
    cursor: pointer;
    border-radius: 4px;
    background-color: #D9D9D9;
    display: inline-flex;
    padding: 5px 3px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FEFEFE;
    z-index: 2;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;
}

.profile-image-container:hover .profile-image {
    opacity: 0.7;
    /* Decrease opacity on hover */
    cursor: pointer;

}




.edit-mode {
    background-color: var(--primary-black) !important;
}

.upload-img {
    padding-right: 8px;
    padding-top: 8px;
    position: absolute;
    display: flex;
    right: 0;
    top: 8px;
    visibility: hidden;
    z-index: 2;
    cursor: pointer;
}

.profile-image-container:hover .profile-image-upload {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
}

.profile-image-container:hover {
    .upload-img {
        visibility: visible;
        opacity: 1;
    }
}
.verifyit{
    color: #C1E0FF;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: 36px;
text-decoration-line: underline;
cursor: pointer;
}
.signup-popup3{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 100%;
    height: auto;
    /* max-height: 462px; */
    background: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
}
.Enter-otp3{
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    max-width: 237px;
    margin-top: 55px;
}
.proceed3{
    display: inline-flex;
height: 32px;
padding: 10px 14px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
margin-top: 26px;
color: #FFF;
leading-trim: both;
text-edge: cap;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 4px;
border: 1px solid  #FEFEFE;
background:  #1F262D;
}


@media (max-width:862px) {
    .address-detail {
        width: 100%;
    }

    .names1 {
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        align-items: baseline;
        width: 74%;
    }

    .dob {
        margin-top: 0px;
        width: 100%;
        align-items: baseline;

    }

    .dob input {
        width: 100%;
    }

    .dob-gender {
        flex-direction: column;
        width: 74%;
        align-items: baseline;
        gap: 32px;
    }

    .firstname,
    .lastname {
        width: 100%;
    }

    .firstname input,
    .lastname input {
        width: 100%;
    }

    .gender {
        width: 100%;
        align-items: baseline;

    }

    .gender select {
        width: 100%;
    }

    .date-pic-dob{
        width: 100% !important;
        padding-left: 4px;
    }
}

@media (max-width:590px) {
    .names1 {
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
    }

    .dob {
        width: 100%;
        justify-content: center;
        align-items: baseline;
    }

    .gender {
        width: 100%;
        justify-content: center;
        align-items: baseline;
    }

    .address-detail {
        width: 87%;
    }

    .personal-detail {
        width: 87%;
    }

    .profile-name {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .town-city select {
        width: 100%;
        height: 48px;

    }

    .State input {
        width: 97%;
    }


}