.search-result{
    background-color: var(--primary-black);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\1.svg'), url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\3.svg');
    background-repeat: no-repeat;
    background-position: top left, right bottom;
    width: 100%;
    
}
.results{
    color: #FEFEFE;
text-align: center;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 133.333% */
max-width: 653px;
margin-top: 80px;
/* margin-bottom: 72px; */

}
.filter-cards2{
    max-width: 1198px;
    justify-content: center;
}
.cards2{
    max-width: 100%;
    margin-top: 90px;
}