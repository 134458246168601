.upper-calender{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    display: flex;
    justify-content: center;
    width: 54%;
}
.notoshow{
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    max-width: 680px;
    flex-shrink: 0;

}
/* *,
*::after,
*::before {
    box-sizing: border-box;
} */
.event-carousel{
    width: 70%;
}
@media (max-width:700px) {
    .upper-calender{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 48px;
    }
}