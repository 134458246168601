.ticket-checkout {
    background-color: #FEFEFE;
    max-width: 720px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Add more styles for the ticket checkout pop-up here */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;

}
.ckeck-location{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

/* Style for the "Close" button within the ticket checkout pop-up */
.ticket-checkout-button {
    background-color: #FEFEFE;
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 0px;
}

.check-detail {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 24px;

}

.ckeck-date-location {
    display: flex;
    flex-direction: column;
    align-items: baseline;


}

.check-location {
    color: #262626;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
}

.ckeck-heading {
    color: #0E1111;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 90%;

}

.check-date,
.location-language {
    color: #262626;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
}

.ticket-category {
    min-height: 111px;
    width: 90%;
    border-radius: 8px;
    border: 1px solid #262626;
    background-color: #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

}

.category-inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.add {
    display: flex;
    width: 85px;
    height: 32px;
    padding: 1px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    color: #FFF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.category-detail {
    display: flex;
    flex-direction: column;
    align-items: baseline;

}

.category-name {
    color: #0E1111;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */

}

.category-price {
    color: var(--bg-color, #262626);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */

}

.category-description {
    display: flex;
    height: 24px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #0E1111;
    color: #0E1111;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    cursor: pointer;
}

.outer-category {
    display: flex;
    width: 90%;
    justify-content: center;
    flex-direction: column;
    padding: 8px;

}

.add-ticket {
    justify-content: center;
    align-items: center;
    display: flex;
}

.description {
    display: flex;
    justify-content: flex-start;
    text-align: initial;
    margin-top: 24px;
    margin-bottom: 32px;
    color: #0E1111;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 169.231% */
}

.increment-decrement {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.ticket-count {
    display: flex;
    padding: 0px 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color: #FEFEFE;
    color: #1F262D;

    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.proceed-btn {
    display: inline-flex;
    padding: 6px 25px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #1F262D;
    background-color: #EFEFEF;
    margin-top: 32px;
    cursor: pointer;
}
.proceed-btn:hover{
    background-color: var(--primary-black) !important;
    color: var(--primary-white);
}

.render-left {
    width: 140px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: #CA0B4A;
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 169.231% */
    margin-bottom: 12px;
}

.render-fast {
    width: 88px;
    height: 20px;
    border-radius: 4px;
    background-color: #FFF0A1;
    color: #0E1111;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 169.231% */
    margin-bottom: 12px;

}

.Check-details {
    color: #0E1111;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    /* 131.25% */
    margin-top: 0px;
    margin-bottom: 0px;
    width: 90%;
    display: flex;
    margin-bottom: 48px;
}

.price-detail {
    height: 100%;
    width: 90%;
    border-radius: 8px;
    border: 1px solid #262626;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.checkname{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 64px;
    width: 96%;
    gap: 4px;
}
.checkall{
    width: 90%;
    border-radius: 8px;
background-color:  #EFEFEF;
display: flex;
align-items: center;
min-height: 49px;
justify-content: center;

}
.event-title{
    color:  #0E1111;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 42px; /* 210% */
text-align: left;
}
.event-langu{
    color:  #262626;
font-family: Open Sans;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 42px; /* 323.077% */
}
.event-loc{
    width: 241px;
    color:  #262626;
font-family: Open Sans;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 123.077% */
display: flex;
margin-top: 19px;
margin-bottom: 16px;
align-items: baseline;
text-align: start;
gap: 8px;

}
.promo-text{
    width: 100%;
    max-width: 370px;
}
.promo-area{
    display: flex;
    margin-top: 38px;
    gap: 8px;
    width: 100%;
    justify-content: center;
}
.promo-text input{
    max-width: 370px;
    width: 100%;
height: 50px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid  #262626;
background-color:  #EFEFEF;
text-align: center;
color:  #959595;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */
text-transform: uppercase;

}
.apply{
    display: flex;
width: 85px;
height: 50px;
padding: 0px 14px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 4px;
border: 1px solid  #FEFEFE;
background-color: #1F262D;
color: #FFF;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
}
.total{
color:  #0E1111;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
display: flex;
margin-top: 64px;
width: 90%;
margin-bottom: 12px;

}
.ticket-counts{
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 8px;
}
.ticket-type{
    display: flex;
    justify-content: space-between;

}
.ticket-name{
    color:  #0E1111;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
max-width: 272px;
width: 100%;
display: flex;
}
.ticket-number{
    color:  #0E1111;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
max-width: 110px;
width: 100%;
height: 17px;
display: flex;
}
.ticket-price{
    color:  #262626;

/* font-family: Montserrat; */
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */
max-width: 103px;
width: 100%;
display: flex;
justify-content: flex-end;
}
.total-price{
    width: 90%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid  #959595;
    margin-top: 8px;
    margin-bottom: 40px;
    padding-top: 8px;
    color:  #262626;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 137.5% */

}
.Promo-applied{
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;

}
.code-discount{
    color: #959595;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.code{
    color:  #959595;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}
.code-price{
    color:  #262626;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
}
.checkout{
    display: inline-flex !important;
height: 48px;
padding: 0px 25px !important;
justify-content: center !important;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
background-color:  #EFEFEF !important;
color:  #352b2b;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 31px;
}
.event-dat{
    color: #262626;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
}
.notsign-details{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%;
}
.not-details{
width: 100%;
}
.not-input{
width: 90% !important;
height: 50px !important;
    flex-shrink: 0;
    border-radius: 8px !important;
    border: 1px solid #262626 !important;
    background-color: #EFEFEF;
    text-align: center;
    color: #959595;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 10px !important;
    padding: 0 !important;
    position: relative;
}
.or2{
    color: #000;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 91.667% */
width: 26px;
height: 20px;
flex-shrink: 0;
}
.continue-sign{
    width: 90%;
    height: 50px;
    border-radius: 8px;
border: 1px solid  #262626;
background:  #1F262D;
color: #FEFEFE;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 137.5% */
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}
.error-message{
    position: relative;
    top: -8px;
    color: orangered;

}
.promomessage{
    position: relative;
    top: 4px;
    color: orangered;

}
.payment-iframe{
    width: 100%;
    max-width: 467px;
    height: 100%;
    z-index: 4;

}
.promo-success{
    color: green;

}
@media (max-width:619px) {
    .promo-area{
        flex-direction: column;
        align-items: center;
    }
    .promo-text input{
        width: 95%;
    }
}