.App {
  font-family: sans-serif;
}

.slider {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 48px;
  border-radius: 4.857px;
border: 0.607px solid  #FEFEFE;
}

.slide {
  width: 100%;
  transition: all ease-out 0.2s;
  border-radius: 10px;
}

.prev_btn {
  position: absolute;
  width: 50px;
  font-size: 20px;
  height: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border: none;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  z-index: 10;
  transition: all ease 0.1s;
}
.cleft, 
.cright{
  opacity: 0;
}
.prev_btn:hover {
  background-color: rgba(0, 0, 0, 0.5);

  .cleft
  {
    opacity:1;
  }
}

.next_btn {
  position: absolute;
  width: 50px;
  font-size: 20px;
  height: 100%;
  top: 50%;
  right: 0;
  border: none;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  transform: translateY(-50%);
  z-index: 10;
}

.next_btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
  transition: all ease 0.1s;

  .cright
  {
    opacity:1;
  }
}

.heading-img {
  color: #FEFEFE;
  text-align: center;
  font-family: Raleway;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  z-index: 1000;
}

.book-now {
  position: absolute;
  color: #FEFEFE;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  left: 123px;
  top: 65px;
  z-index: 1;
display: flex;
justify-content: center;
align-items: flex-end;
gap: 12px;
text-align: left;
align-items: center;

}

.book-now img{
  width: 40px;
  height: 40px;
border-radius: 24px;
}

.book-now-location {
  position: absolute;
  color: #FFF;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;

  left: 123px;
  top: 105px;


}

.book-now-btn {
  position: absolute;
  bottom: 59px;
  left: 123px;
  z-index: 1;
}
.book-now-btn a{
display: flex;
justify-content: center;
align-items: center;
flex-shrink: 0;
color: var(--primary-black);
border-radius: 8px;
background:  #FEFEFE;
padding: 3px 7px;
border-radius: 8px;
background: var(--container-white, #FEFEFE);
width: 101px;
height: 24px;
flex-shrink: 0;
color: var(--primary-black, #0E1111);
text-align: center;
leading-trim: both;
text-edge: cap;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.available-ticket{
  position: absolute;
  color: #FFF;
text-align: center;
font-family: Raleway;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
bottom: 130px;
left: 123px;
z-index: 1;

}
.location-date{
  position: absolute;
  bottom: 105px;
  left: 123px;
  color: #FFF;
text-align: center;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
z-index: 1;
display: flex;
}
.topbannerimg{
  width: 100%;
  object-fit: cover;
  filter: brightness(0.4);

}
.for-text{
  z-index: 2;
}
.edate{
color:  rgba(255, 240, 161, 1);
;
}


/* =============================== */
.cont {
  /* border: 1px solid red; */
  width: 100%;
}
.cont1 {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;

  /* width: */
}
.active {
  border: 2px solid black;
}

.cont1 > div:nth-child(1) {
  width: 100%;
  border: 1px solid  #FEFEFE;
}

.cont1 > div:nth-child(2) {
  width: 30%;
}
.box1 {
  margin-left: 50px;
}


.cont2 {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: space-around;
  width: 65%;
  gap: 16px;

}

.cont2 img {
  justify-content: center;
}
.cont2 div {
  width: 16%;
  /* border: 1px solid black; */
}
.cont img {
  width: 100%;
  height: 100%;
}


.btn {
  border: 1px solid red;
  size: 60px;
}

.eventprev{
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventprev img{
  height: 40px;
  width: 40px;
  }

.eventnext{
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventnext img{
  height: 40px;
  width: 40px;
  }

 
 
  .paragraphs{
    position: absolute;
display: flex;
flex-direction: column;
gap: 12px;
top: 37px;
/* opacity: 0; */
transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

.headingName{
display: flex;
opacity: 0.8;
background-color:  #262626;
max-width: 418px;
height: 56px;
width: 100%;
align-items: center;

}
.headingName h1{
color: var(--primary-white);
color: #FFF;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 42px; /* 131.25% */
margin-top: 0px;
margin-bottom: 0px;
padding-left: 38px;
}

.desc{
max-width: 418px;
height: 56px;
flex-shrink: 0;
opacity: 0.8;
background-color:  #262626;
width: 100%;
align-items: center;

}
.desc p{
  color:  #EFEFEF;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */  
  padding-left: 38px;
  margin-top: 0px;
margin-bottom: 0px;
text-align: initial;


}

.dated{
display: flex;
  justify-content: center;
  align-items: center;
  max-width: 174px;
  height: 27px;
  flex-shrink: 0;
  opacity: 0.8;
background-color:  #262626;
align-items: center;

}
.dated p{
color:  #FEFEFE;
font-family: Open Sans;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 42px;
padding-left: 38px;
margin-top: 0px;
margin-bottom: 0px;
text-align: initial;

 /* 323.077% */}

.cont1 > div:nth-child(1):hover {
.paragraphs{
  opacity: 1;
  visibility: visible;

}

}
@media (max-width:888px) {
  .book-now {
    position: absolute;
    color: #FEFEFE;
    text-align: center;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    left: 51px;
    top: 54px;
    z-index: 1;
    line-height: 24px;

}
.book-now img{
  width: 40px;
  height: 40px;
}
.book-now-location {
  position: absolute;
  color: #FFF;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  left: 51px;
  top: 89px;
}
.available-ticket {
  position: absolute;
  color: #FFF;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  top: 156px;
  left: 51px;
  z-index: 1;
}
.location-date {
  position: absolute;
  top: 182px;
  left: 51px;
  color: #FFF;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 1;
}
.book-now-btn {
  position: absolute;
  top: 212px;
  left: 51px;
  z-index: 1;
}
}
@media (max-width:800px) {
  .book-now{
    font-size: 28px;
    top: 60px;
        text-align: left;
        line-height: 21px;
  }
  .book-now-location{
    font-size: 28px;
    top: 98px;
  }
  .available-ticket {
    position: absolute;
    color: #FFF;
    text-align: center;
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    bottom: 65px;
    left: 51px;
    z-index: 1;
}


.location-date {
    position: absolute;
    bottom: 43px;
}
.book-now-btn {
  position: absolute;
  bottom: 9px;
  left: 51px;
  z-index: 1;
}
  
}
/* @media (max-width:596px) {
  .book-now {
    position: absolute;
    color: #FEFEFE;
    text-align: center;
    font-family: Raleway;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    left: 87px;
    top: 17px;
    z-index: 1;
}
.book-now-location {
  position: absolute;
  color: #FFF;
  font-family: Raleway;
  font-size: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  left: 85px;
  top: 61px;
}

.available-ticket {
  position: absolute;
  color: #FFF;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  top: 159px;
  left: 84px;
  z-index: 1;
}
.location-date {
  position: absolute;
  top: 189px;
  left: 86px;
  color: #FFF;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 1;
}
.book-now-btn {
  position: absolute;
  top: 222px;
  left: 84px;
  z-index: 1;
}
} */
@media (max-width:450px) {
  .book-now {
    position: absolute;
    color: #FEFEFE;
    font-family: Raleway;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    left: 52px;
    top: 21px;
    line-height: 20px;

    z-index: 1;
}
.book-now-location {
  position: absolute;
  color: #FFF;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  z-index: 1;
  left: 52px;
  top: 59px;

}
.available-ticket {
  position: absolute;
  color: #FFF;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  top: 129px;
  left: 52px;
  z-index: 1;
}
.location-date {
  position: absolute;
  top: 153px;
  left: 52px;
  color: #FFF;
  text-align: center;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 1;
}
.book-now-btn {
  position: absolute;
  top: 186px;
  left: 52px;
  z-index: 1;
}
}

@media (max-width:388px) {
.book-now {
    position: absolute;
    
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    left: 25px;
    top: 16px;
    z-index: 1;
}
.book-now img{
height: 35px;
width: 35px;
}
.book-now-location {
    position: absolute;
    color: #FFF;
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    z-index: 1;
    left: 25px;
    top: 51px;
}
.available-ticket {
    font-size: 16px;
    top: 103px;
    left: 25px;
    z-index: 1;
}
.location-date {
  top: 124px;
  left: 25px;
  font-size: 12px;
}
.book-now-btn {
    position: absolute;
    top: 149px;
    left: 25px;
    z-index: 1;
}
.book-now-btn button {
    width: 80px;
    height: 18px;
    flex-shrink: 0;
    color: var(--primary-black);
    border-radius: 8px;
    background: #FEFEFE;
    padding: 0px 0px;
}
.book-now-btn a{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FEFEFE;
    padding: 3px 7px;
    border-radius: 8px;
    width: 86px;
    height: 21px;
    font-family: Raleway;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}
}

@media (max-width:324px) {
.book-now {
  position: absolute;
    
  font-family: Raleway;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  left: 16px;
  top: 5px;
  z-index: 1;
}
.book-now img{
height: 35px;
width: 35px;
}
.book-now-location {
    position: absolute;
    color: #FFF;
    font-family: Raleway;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 1;
    left: 16px;
    top: 45px;
}
.available-ticket {
    font-size: 16px;
    top: 92px;
    left: 16px;
    z-index: 1;
}
.location-date {
  top: 114px;
  left: 16px;
    font-size: 12px;
}
.book-now-btn {
    position: absolute;
    top: 134px;
    left: 16px;
    z-index: 1;
}
.book-now-btn button {
    width: 80px;
    height: 18px;
    flex-shrink: 0;
    color: var(--primary-black);
    border-radius: 8px;
    background: #FEFEFE;
    padding: 0px 0px;
}
}