.App {
    text-align: center;
}

.carousel-container {
    width: 80%;
    /* overflow: hidden; */
    position: relative;
    margin: 0 auto;
}

.carousel {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
}

.carousel-card1 {
    flex: 0 0 25%;
    /* Show 4 cards at a time */
    background-color: #fff;
    transform: translateX(100%);
    transition: transform 0.5s, opacity 0.5s;
    

}

.carousel-card1.active {
    transform: translateX(0);
    opacity: 1;
}

.image {
    flex: 1;
    overflow: hidden;
    margin: 0; 
  padding: 0;
  min-width: 252px;
  min-height:  247.08px;
  display: flex;
}

.image img {
    width: 100%;
    max-height: 252px;
    object-fit: cover;
    border-radius: 16px 16px 0px 0px;
    display: block; /* Make sure the image is treated as a block element */
    width: 100%; /* Ensure the image takes the full width of its parent div */
    height: auto; 
    
}

.details {
    text-align: start;
    background-color: var(--primary-black);
}

.prev-button1,
.next-button1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
}

.prev-button1:hover,
.next-button1:hover {
    transform: translateY(-50%) scale(1.5);
    transition: transform 0.3s ease-in-out;
    color: var(--primary-white);
}

.prev-button1 {
    left: -57px;
}

.next-button1 {
    right: -57px;
}

.carousel {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    justify-content:center ;
    /* Adjust margin to create spacing between cards */
    transition: transform 0.3s ease-in-out;
    position: relative;
    transform-origin: bottom; /* Set the transform origin to the bottom */
}

.carousel-card1 {
    flex: 0 0 calc(25% - 16px);
    /* Show 4 cards at a time with 16px gap */
    background-color: #fff;
    /* max-height: 400px; */
    border-radius: 16px 16px 0px 0px;
    margin: 0 8px;
    max-width: 252px;
    /* Create spacing between cards */
    transform: translateX(0);
    /* Set the initial position to 0 */
    transition: transform 0.3s ease-in-out;
    position: relative;
    transform-origin: bottom; /* Set the transform origin to the bottom */
}

.tittle {
    color: var(--primary-white);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    height: 32px;
    background-color: var(--Dark-blue);
    padding-left: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.date,
.location {
    color: #EFEFEF;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.price {
    display: inline-block;
    height: 24px;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 150px;
    background: #262626;
    color: var(--hint-text, #959595);
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 8px;
    margin-left: 8px;
}

.icon {
    padding-left: 8px;
    padding-right: 8px;
}

.icon-text {
    padding-top: 8px;
}

.available {
    display: none;

}
.carousel-card1 {
    position: relative; /* Relative positioning for pseudo-elements */
    /* Other styles for your carousel card */
  
    /* Define the initial box shadow (no shadow) */
    box-shadow: none;
  
    /* Add a smooth transition for the box-shadow property */
    transition: box-shadow 0.3s ease-in-out;
  }

  
        
  

.carousel-card1:hover {
    /* transform: scaleY(1.02);  */
    transition: translate 0.3s ease-in-out;
    height: calc(110% + 10px); 
    translate: 0px  -10px 0px;
    /* box-shadow: 0px -70px 150px 0px  #404040; */
    height: 110%;

    

    .available {
        display: block;
        display: inline-flex;
        padding: 2px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 150px;
        background-color: var(--primary-white);
        position: absolute;
        right: 7px;
        top: 7px;

        color: #0E1111;

        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
    }
    .price{
        background-color: var(--primary-white);
        color: var(--primary-black);
    }
}

.prev-button1,
.next-button1{
    padding: 10px 0px;

}



.availability-status-red {
    display: inline-flex;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 150px;
    background-color: #CA0B4A;
    position: absolute;
    right: 7px;
    top: 7px;
    color: #FEFEFE;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.availability-status-yellow {
    display: inline-flex;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 150px;
    background: #FD5;
    position: absolute;
    right: 7px;
    top: 7px;

    color: #0E1111;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 169.231% */
}
.carousel-card1{

    background: linear-gradient(0deg, var(--primary-black) 0%, #eeeeee 100%);
    border-radius: 16px;
    padding: 1px;
   /* box-sizing: border-box; */
}

/* .carousel-card1{
    background-image: url("C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\blur.svg");
    background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
} */



.carousel-card1 {
    flex: 0 0 calc(50% - 16px);
    margin: 0 8px;
}

/* Media query for screens with a maximum width of 1100px */
/* @media (min-width: 1000px) {
    .carousel-card1 {
        flex: 0 0 calc(50% - 16px);
    }
} */
/* @media (max-width: 1000px) {
    .carousel-card1 {
        flex: 0 0 calc(50% - 16px);
    }
} */

/* Media query for screens with a maximum width of 600px */
@media (max-width: 999px) {
    .carousel-card1 {
        flex: 0 0 calc(100% - 16px);
    }
}
@media (max-width: 1075px) {
    .prev-button1,
.next-button1{
        padding: 10px 0px;
    }
    .prev-button1 {
        left: 30px;
    }
    .next-button1 {
        right: 30px;
    }
}
@media (max-width: 895px) {

    .prev-button1 {
        left: -34px;
    }
    .next-button1 {
        right: -34px;
    }
}
@media (max-width: 490px) {

    .prev-button1 {
        left: -71px;
    }
    .next-button1 {
        right: -71px;
    }
}

   @media (max-width: 363px){
       
       .prev-button1, .next-button1 {
           position: relative;
    transform: translateY(0%);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
}
.prev-button1 {
    left: -20px;
}
.next-button1 {
    right: -20px;
}


}
