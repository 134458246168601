.support-btn {
    display: flex;
    gap: 32px;
    margin-bottom: 96px;
    justify-content: center;

}

.previous-ticket,
.raise-query {
    width: 323px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    /* 262.5% */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.Ticket-heading {
    color: #FFF;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    /* 131.25% */
}

.raise-div {
    max-width: 1120px;
    width: 100%;
    height: 648px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    margin-bottom: 180px;
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ticket-id {
    width: 90%;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: #262626;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}
.component-render{
    width: 100%;
}
.close {
    display: flex;
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 12px;
    width: 95%;
}

.id {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #959595;

    /* Body Text 2 - 16px Medium */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}

.open-close {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FEFEFE;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    gap: 3px;
    /* 150% */
}

.info {
    width: 90%;
    flex-shrink: 0;
    border-radius: 8px;
    background-color: #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
    margin: 14px 38px 42px 32px;

}

.inner-cover {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;


}

.issue {
    display: flex;
    justify-content: baseline;
    flex-direction: column;
    text-align: initial;

}

.issue h3 {
    color: #262626;
    margin-top: 0px;
    margin-bottom: 0px;

    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    /* 150% */
}

.issue p {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #262626;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 187.5% */
}

.date-submitted h3 {
    margin-bottom: 0px;
    color: #262626;

    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    /* 150% */
}

.date-submitted p {
    margin-top: 0px;
    color: #262626;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 187.5% */
    display: flex;
}

.issue-description p {
    text-align: initial;
    color: #000;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.raise-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 648px;

    height: 100%;
    padding-top: 32px;
    padding-bottom: 32px;






}

.for-inner-item {
    max-width: 800px;
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex-direction: column;
    /* padding-left: 32px;
    padding-right: 32px; */
}

.dropdowns {
    display: flex;
}

.name-email {
    width: 100%;
    display: flex;
    gap: 32px;
    margin-top: 24px;
}

.name-email input {
    width: 100%;
    display: flex;
    width: 80%;
    height: 40px;
   align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #FEFEFE;
    background: #262626;
    color: var(--primary-white);
        padding-left: 8px;

}

.dropdowns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 40px;
}

.dropdowns select {
    width: 240px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: #262626;
    color: var(--hint-text, #959595);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;

}

.dropdowns select option{
    color: var(--primary-white);
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: #262626;
}
option:hover{
    background-color: red;
}

.subject {
    width: 100%;
    display: flex;


}

.subject input {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    color: var(--primary-white);
    padding-left: 8px;

}

.issue-here {
    width: 100%;
    display: flex;

}

.issue-here textarea {
    width: 100%;
    height: 156px;
    align-items: flex-start;
    border-radius: 6px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    color: var(--primary-white);
    resize: none;
    font-family: Open Sans;
    padding-top: 8px;
    padding-left: 8px;

}
.select-file{
    display: flex;
    width: 100%;
padding: 8px;
align-items: center;
border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color: v #262626;
justify-content: space-between;
}
.select-file-to{
    color:  #959595;

/* Body Text 2 - 16px Medium */
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */

}
.upload{
    display: flex;
height: 32px;
padding: 0px 45px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color:  #FEFEFE;
cursor: pointer;
}
.upload:hover{
    background-color: var(--primary-black);
    color: var(--primary-white);
}
.active1{
    background-color: var(--primary-white);
    color: var(--primary-black);
}
.issue-btn{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.view{
    display: inline-flex;
height: 48px;
padding: 1px 45px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
background-color: #1F262D;
color:  #FEFEFE;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.view1{
    display: none;
    height: 48px;
    padding: 1px 45px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background-color: #1F262D;
    color:  #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.popup1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 680px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 16px;
  background-color:  #FEFEFE;
    border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
}

.content{
    height: 182px;
    width: 80%;
    border-radius: 8px;
background-color:  #EFEFEF;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

}

.great{
    color:  #0E1111;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px; /* 210% */
}
.greatp{
    max-width: 375px;
    height: 60px;
    flex-shrink: 0;
    color: #000;
text-align: center;

font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
}
.check{
    display: flex;
width: 85px;
height: 48px;
padding: 0px 14px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 4px;
border: 1px solid  #FEFEFE;
background-color:  #1F262D;
color: #FFF;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 24px;
cursor: pointer;
}
.upper2{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width:695px) {
    .support-btn{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}
@media (max-width:994px) {
    .name-email{
        flex-direction: column;
        gap: 16px;
        margin-top: 24px;
        justify-content: center;
        align-items: baseline;
        
    }
    .dropdowns{
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
    }
    .dropdowns select{
        width: 83%;
    }
    .raise-div{
        margin-left: 0px;
    
    }
}
@media (max-width:610px) {
    .raise-div{
        padding-left: 0px;
        padding-right: 0px;
        width: 90%;
    }
    .subject input{
        width: 89%;
    }
    .for-inner-item{
        /* padding-left: 32px;
    padding-right: 32px; */
    width: 95%;
    }
    .view{
        display: none;
    }
    .view1{
        display: inline-flex;
    }
    .issue-here textarea{
        width: 90%;

    }
    .select-file{
        width: 85%;

    }
    .submit-btn button{
        background-color: var(--primary-white);
       color: var(--primary-black);
    }
    .info{
        padding: 4px;
    }
    
}
@media (max-width:360px) {
    .support-btn div{
        width: 90%;
    }
    .for-inner-item{
        padding-left: 0px;
    padding-right: 0px;
    }
}