/* SearchableDropdown.css */
.searchable-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .searchable-dropdown input {
    width: 92%;
    height: 26px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    background: var(--system-colors-black-dark);
    color: var(--text-primary, #FEFEFE);

  }
  
  .dropdown-ul {
    list-style: none;
    color: var(--text-primary, #FEFEFE);
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    max-height: 270px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background: var(--system-colors-black-dark);
    z-index: 1;
  }
  
  .dropdown-ul li {
    padding: 10px;
    cursor: pointer;
    color: var(--text-primary, #FEFEFE);
  }
  
  .dropdown-ul li:hover {
    background-color: #f0f0f0;
  }
  