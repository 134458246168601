.pfilter {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

}

.psearch {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pupper {
    max-width: 314px;
    width: 90%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #0E1111;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.pupper input {
    max-width: 314px;
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #0E1111;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding-left: 30px;
}

.pupper img {
    position: absolute;
    right: 8px;
}

.psearch-btn {
    display: flex;
    height: 48px;
    padding: 0px 45px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 24px;
}

.p-filter {
    display: flex;
    height: 48px;
    padding: 0px 21px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    border: 1.5px solid #FEFEFE;
    background-color: #1F262D;
    z-index: 2;
    cursor: pointer;
}

.plower {
    display: flex;
    gap: 8px;
}

.e-menu {
    display: flex;
    gap: 48px;
    margin-top: 60px;
}

.e-menu a {
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    cursor: pointer;
    /* 140% */
}

.e-menu a:hover {
    background-color: var(--primary-white);
    color: var(--primary-black);
    border-radius: 4px;
}

.under-line {
    max-width: 609px;
    width: 90%;
    height: 1px;
    flex-shrink: 0;
    stroke-width: 1px;
    background-color: #EFEFEF;
    margin-top: 30px;
    margin-bottom: 44px;
}

.inner {
    position: relative;
}



.ind1 {
    position: absolute;
    width: 1px;
    height: 17px;
    flex-shrink: 0;
    background-color: #959595;
    top: 34%;
    left: 12%;
}

.ticket-container {
    max-width: 1116px;
    width: 99%;
    min-height: 254px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
}

.inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.detail-left {
    display: flex;
    flex-direction: column;
    gap: 12px;


}

.pname {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 87.5% */
    margin-top: 0px;
    margin-bottom: 0px;
display: flex;
flex-direction: column;
align-items: baseline;
}

.lt {
    color: #EFEFEF;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
}

.dtp {
    display: flex;
    gap: 8px;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;

}

.d {
    color: #1F262D;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 200% */
    background-color: #FEFEFE;
    display: flex;
    height: 24px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.t {
    color: #1F262D;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 200% */
    background-color: #FEFEFE;
    display: flex;
    height: 24px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.p1 {
    border: 1px solid #FEFEFE;
    display: flex;
    height: 24px;
    padding: 0px 19px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 200% */
    width: auto;
}

.loc {
    color: #EFEFEF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    max-width: 401px;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 8px;
    text-align: left;

}

.detail-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.two-box {
    display: flex;
    gap: 15px;
}

.b1 {
    border: 1px solid #FEFEFE;
    width: 120px;
    height: 139.18px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.tag1 {
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.738px;
    /* 98.361% */
    max-width: 86.066px;
}

.three-btn {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.btn1 {
    color: #262626;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #EFEFEF;
    display: flex;
    width: 192px;
    height: 27px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.btn2 {
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    display: flex;
    width: 192px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.btn3 {
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    display: flex;
    width: 192px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.btn2:hover,
.btn3:hover {
    color: var(--primary-black);
    background-color: var(--primary-white);
    cursor: pointer;
}
.overlays{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background color */
        z-index: 1; /* Place it on top of other elements */
        display: flex;
        align-items: center;
        justify-content: center;
      
      
}

@media (max-width:1030px) {
    .inner-container {
        flex-direction: column;
        gap: 37px;
    }

    .ticket-container {
        max-width: none;
        width: auto;
        padding: 24px;
    }
}

@media (max-width:650px) {
    .pfilter {
        flex-direction: column;
        gap: 8px;
    }

    .psearch-btn {
        margin-left: 0px;
    }

    .e-menu {
        flex-wrap: wrap;
        width: 90%;
        justify-content: center;


    }

    .dtp {
        flex-wrap: wrap;
    }
}

@media (max-width:550px) {
    .detail-right {
        flex-direction: column !important;
    }

    .btn1,
    .btn2,
    .btn3 {
        width: 100%;
    }

    .three-btn {
        width: 100%;
    }

    .pupper img {
        right: 8px;
    }

    .pname {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        text-align: left;
    }

    .lt {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        text-align: left;

    }

    .ticket-container {
        width: 80%;
        padding-top: 56px;
        padding-bottom: 49px;
    }
}

.tab-filters1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 8px;
    margin-top: 53px;
    margin-bottom: 83px;
}

.least-view,
.most-viewed,
.most-solds,
.most-viewed,
.least-sold {
    border: 0.688px solid #FEFEFE;
    background-color: #0E1111;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 33px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 24px;
}

.clicked {
    border-radius: 6px;
    border: 1.5px solid #FEFEFE;
    background-color: #FEFEFE;
    color: #0E1111;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}