.bg{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.publish1{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 44px;
    max-width: 1120px;
    width: 90%;
    margin-top: 146px;
}
.main-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
    height: 476px;

}
.main-logo >div img{
    width: 101.849px;
height: 100px;
flex-shrink: 0;
}
.pub-head{
max-width: 346px;
width: 100%;

}
.pub-head h2{
    color: var(--text-secondary, #EFEFEF);
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    flex-shrink: 0;
}
.publish-sign{
    /* width: 100%; */
    width: 475px;
/* height: 350px; */
flex-shrink: 0;
border-radius: 16px;
background: #FAFAFA;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
}
.publish-sign h2{
    margin-top: 28px;
    margin-bottom: 8px;
}
.privacy2{
    margin-top: 50px;
    margin-bottom: 12px;

}
.privacy{
    margin-bottom: 12px;

}
.text{
    margin-top: 66px;
    margin-bottom: 41px;
  
  }
  .back{
    display: inline-flex;
height: 32px;
padding: 10px 14px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 4px;
border: 1px solid  #FEFEFE;
background-color: #1F262D;
color: #FFF;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 0px;
  }
  .signup-btn-div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 30px;
  }
  .email2, .google2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 370px;
    height: 50px;
    border-radius: 8px;
    background: var(--primary-black);
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    gap: 12px;
    cursor: pointer;
}
.new1{
margin-top: 32px;
}
.started1{
margin-bottom: 28px;
}


  
@media ((max-width:1070px)) {
    .publish1{
        flex-direction: column;
    }
    .main-logo >div img{
        width: 152.774px;
        height: 150px;
        flex-shrink: 0;
    }
    .main-logo{
        height: auto;
    }
}
@media (max-width:512px) {
    .main-logo{
        flex-direction: column;
        gap: 0px;
    }
    .publish1{
        margin-top: 40px;
    }
    .sign{
        width: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .publish-sign{
        width: 100%;
    }
    .pub-head h2{
        margin-top: 0px;
        margin-bottom: 0px;
        text-align: center;
    }
    .signin-option{
        width: 100%;
    }
    .email1, .google1{
        width: 95% !important;
    }
    .mobile{
        width: 100%;
    }
    .inner{
        width: 75%;
    }
    .plogin{
        width: 90%;
    }
    
    
}
@media (max-width:350px) {
    .pub-head h2{
        font-size: 36px;
    }
    .publish-sign h2{
        font-size: 36px;

    }
    .outer{
        width: 90%;
    }
    .ind, 
    .ind1{
        display: none;
    }
    .mobile-input{
        width: 93% !important;
        font-size: 10px !important;
    }
    .name1 input, .password1 input, .confirmpassword1 input, .general1 input{
        width: 85%;
    }
    .reset-email{
        width: 90%;
    }
}
@media (max-width: 512px){

    .email2, .google2 {
        width: 96%;
    }
}