.general-section {
    padding-top: 60px;
    background-color: var(--primary-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.general-heading {
    color: #FEFEFE;
    font-family: var(--font-montserrat);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;

}

.general-line {
    display: block;
    margin: 0 auto;
    /* Center the line image horizontally */
    padding-bottom: 48px;
}

.term-heading {
    max-width: 736px;
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
}
.terms{
    color:  #FEFEFE;
text-align: center;
max-width: 795px;
font-family: Open Sans;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 150% */
}
@media (max-width:660px) {
    .terms{
        width: 90%;
    }
}
@media (max-width:450px) {
    .terms{
        font-size: 18px;
    }
}