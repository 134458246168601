.bg-child,
.searchbar-item {
  position: absolute;
  top: 260.9px;
  left: 948.74px;
  width: 331.26px;
  height: 381.1px;
}

.searchbar-item {
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--system-colors-black-dark);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--container-white);
  box-sizing: border-box;
  height: 48px;
}

.vector-icon1 {
  position: absolute;
  top: 15.5px;
  right: 16.79px;
  width: 17.21px;
  height: 17.21px;
}

.event-name1 {
  position: absolute;
  top: calc(50% - 17px);
  left: 30.67px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 152px;
  height: 33px;
}

.searchbar1 {
  position: relative;
  min-width: 270px;
  min-height: 48px;
}

.city-inner {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--system-colors-black-dark);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--container-white);
  box-sizing: border-box;
  width: 195px;
  height: 48px;
}

.city3 {
  position: absolute;
  top: calc(50% - 17px);
  left: 23px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 110px;
  height: 33px;
}

.city-child1 {
  position: absolute;
  top: 18.94px;
  right: 5.44px;
  width: 18.12px;
  height: 10.56px;
}

.city2 {
  position: relative;
  min-width: 195px;
  min-height: 48px;
}

.onboard-search-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.search1 {
  position: relative;
}

.frame-parent,
.search-btn {
  display: flex;
  flex-direction: row;
}

.search-btn {
  border-radius: var(--br-5xs);
  background-color: var(--system-colors-black-grey);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) inset;
  border: 1px solid var(--container-white);
  box-sizing: border-box;
  height: 48px;
  overflow: hidden;
  padding: var(--padding-6xs) var(--padding-26xl);
  align-items: center;
  justify-content: center;
  color: var(--container-white);
  font-family: var(--font-open-sans);
}

.frame-parent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.frame-inner {
  border-radius: var(--br-5xs);
  background-color: var(--system-colors-black-blue);
  border: 1px solid var(--container-white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 19px 0;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  justify-content: flex-end;
}

.frame-child,
.frame-item {
  /* position: absolute; */
  top: 57px;
  left: 175px;
  width: 291.56px;
  height: 373px;
}

.frame-item {
  top: 260px;
  left: 148.02px;
  border-radius: var(--br-base);
  background-color: var(--system-colors-black-blue);
  width: 144.73px;
  height: 296.05px;
  transform: rotate(30deg);
  transform-origin: 0 0;
}

.rectangle-div {
  top: 342.14px;
  left: 177.29px;
  transform: rotate(30deg);
  opacity: 0.75;
}

.frame-child1,
.frame-child2,
.frame-child3,
.frame-child4,
.rectangle-div {
  position: absolute;
  border-radius: var(--br-base);
  background-color: var(--system-colors-black-blue);
  width: 144.73px;
  height: 296.05px;
  transform-origin: 0 0;
}

.frame-child1 {
  top: 418.78px;
  left: 189.15px;
  transform: rotate(30deg);
  opacity: 0.5;
}

.frame-child2,
.frame-child3,
.frame-child4 {
  top: 281px;
  left: 1472.47px;
  transform: rotate(150deg);
}

.frame-child3,
.frame-child4 {
  top: 363.14px;
  left: 1443.2px;
  opacity: 0.75;
}

.frame-child4 {
  top: 439.78px;
  left: 1431.34px;
  opacity: 0.5;
}

.main-logo-icon1 {
  /* position: absolute; */
  top: calc(50% - 75px);
  left: calc(50% - 276.5px);
  width: 152.77px;
  height: 150px;
}

.youth-events {
  margin: 0;
  /* position: absolute; */
  top: 25px;
  left: 164px;
  font-size: 48px;
  font-weight: 700;
  font-family: var(--font-montserrat);
}

.unlock-the-citys {
  margin: 0;
  /* position: absolute; */
  top: 75px;
  left: 171px;
  font-size: 24px;
  font-weight: 400;
  font-family: var(--font-open-sans);
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  max-width: 382px;
}

.main-logo-parent {
  display: flex;
  justify-content: center;
  margin-top: 128px;
  font-size: 48px;
  color: var(--container-white);
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore {
  font-family: var(--font-open-sans);
  text-align: center;
color: rgba(149, 149, 149, 1);
}
/* .frame, */


.frame {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  font-size: var(--font-size-base);
  color: var(--system-colors-light-grey);
  font-family: var(--font-montserrat);
}

.bg {
  background-color: var(--primary-black);
  min-height: 100vh;
}

.headings{
  margin-left: 16px;
}
.text{
  margin-top: 256px;
}

.bg {
  background-image: url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\1.svg'), url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\2.svg'), url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\3.svg');
  background-repeat: no-repeat;
  background-position: top left, bottom left, right bottom;
  width: 100%;
}






/* Large screens and desktops */
@media (min-width: 1024px) {
 
}

/* Tablets and medium-sized screens */
@media (max-width: 768px) {
  .frame-parent{
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .frame-inner{
    width: 80%;
  }
  .searchbar1{
    width: 100%;
  }
  .searchable-dropdown {
    display: flex !important;
}
.onboard-search-wrapper {
  width: 100%;
}
.city2 {
  width: 100%;
}
.main-logo-parent {
  flex-direction: column;
  align-items: center;
  margin-top: 64px;

}
.bg {
  background-image: url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\1.svg'), url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\3.svg');
  background-repeat: no-repeat;
  background-position: top left, center right;
  width: 100%;
}
.city-child1 {
  position: absolute;
  top: 18.94px;
  right: 31.44px;
  width: 18.12px;
  height: 10.56px;
}
.vector-icon1 {
  position: absolute;
  top: 15.5px;
  right: 30.79px;
  width: 17.21px;
  height: 17.21px;
}



 
}

/* Small screens and mobile devices */
@media (max-width: 475px) {
  .youth-events {
    font-size: 32px;
    text-align: center;
}
.unlock-the-citys {
  text-align: center;
}
.city-child1 {
  position: absolute;
  top: 18.94px;
  right: 15.44px;
  width: 18.12px;
  height: 10.56px;
}
.vector-icon1 {
  position: absolute;
  top: 15.5px;
  right: 15.79px;
  width: 17.21px;
  height: 17.21px;
}




}
@media (max-width: 375px) {

}
@media (max-width: 325px) {

}
