.calendar {
    color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: column;
    width: 100%;
}
.table {
    display: table;
    width: 100%;
}
.table-row {
    display: table-row;
}
.table-cell {
    display: table-cell;
    padding: 0.5rem;
    text-align: center;
    vertical-align: middle;
    border-radius: 0.25rem;
}
.calendar-date {
    cursor: pointer;
}
.calendar-date:hover {
    background: var(--primary-white);
    color: var(--primary-black);
    border-radius: 150px;
}
.today{
    border: 1px solid var(--primary-white);
    border-radius: 150px;
}
.calendar-date.picked {
    background-color: var(--primary-white); 
    color: var(--primary-black);
    border-radius: 150px;
   
  }
  .past-date{
    color: gray;
  }
  .future-event{
    background-color: #5c6f81;
    border-radius: 26px;
    color: white;
  }