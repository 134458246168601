/* CustomSearchBar.css (or define styles here) */
.custom-search-bar {
    display: flex;
    align-items: center;
    /* margin: 20px auto; */
  }
  
  .custom-search-bar{
    border: 1px solid #ccc;
    border-radius: 4px;
   
  }
  .custom-search-bar input{
    /* width: 200px; */
    width: 100%;
    height: 26px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    background: var(--system-colors-black-dark);
    color: var(--text-primary, #FEFEFE);
  }
  
  button {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }