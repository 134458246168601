.Phome {
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.phomepage{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.Phome h2 {
    color:  #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin-top: 64px;
    margin-bottom: 0px;
}

.Phome p {
    max-width: 935px;
    color:  #FEFEFE;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
.publish-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 242px;
height: 32px;
flex-shrink: 0;
color: rgba(254, 254, 254, 1);
border: 1px solid rgba(254, 254, 254, 1);
box-sizing: border-box;
border-radius: 8px;
margin-top: 48px;
margin-bottom: 64px;
gap: 17px;
cursor: pointer;
}
.publish-btn:hover{
    background-color: var(--primary-white);
    color: var(--primary-black);
}
.publish-detail{
display: flex;
max-width: 1120px;
width: 90%;
justify-content: space-between;
align-items: center;
overflow-x: scroll;
gap: 40px;

}
.boxs{
    width: 244px;
height: 283px;
flex-shrink: 0;
border: 1px solid  #FEFEFE;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
position: relative;
cursor: pointer;
}
.how-much{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 100% */
    max-width: 215px;
height: 39px;
flex-shrink: 0;
}
.tag{
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 100% */
    max-width: 175px;
flex-shrink: 0;
}
.p{
    width: 90%;
display: flex;
align-items: baseline;

}
.phead{
    color:  #FEFEFE;
text-align: center;
font-family: Montserrat;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 100% */
margin-top: 64px;
margin-bottom: 28px;
width: 90%;
display: flex;
justify-content: baseline;
}
.p a{
    color:  #959595;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.p a:hover{
    color: var(--primary-white);
    background-color: transparent;
}
.view-all{
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 8px; 
   flex-shrink: 0;
}

.pout{
    border-radius: 16px;
    border: 1px solid  #FEFEFE;
    background-color:  #0E1111;
    max-width: 1120px;
    width: 99%;
    min-height: 539px;
    display: flex;
    justify-content: center;
    align-items: center;
    

}
    .wrapper1 {
        display: flex;
        justify-content: center;
        width: 45%;
    }

.p-noti{
    max-width: 1149px;
    width: 90%;
    height: 100%;
    flex-shrink: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 24px;
    padding-left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    margin: 40px 80px 40px 80px;
}
.p-mess{
    max-width: 1149px;
    width: 90%;
    height: 100%;
    flex-shrink: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 24px;
    padding-left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    margin: 40px 80px 40px 80px;  
}
.message{
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: var(--Dark-blue);
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 991px;
    width: 100%;
}
.p-name{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
}
.p-date{
    padding-right: 30px;
    color:  #959595;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 175% */
}
.mess-heading{
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding-left: 31PX;
    margin-bottom: 0px;
    margin-top: 0px;
}
.mess-text{
    color: #FEFEFE;
    padding-left: 31PX;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
    margin-top: 0px;
}
.box-img{
    position: absolute;
    top: 15px;
    right: 14px;
display: none;
}
.boxs:hover{
    transition: all 1s ease;
    border: 1px solid  #FEFEFE;
    background-color: var(--container-blue, #1F262D); 
    justify-content: center;   
    color:  #EFEFEF;
    .how-much{
        opacity: 0;
        visibility: hidden;
        font-size: 0px;
        height: 0px;
    }

}
.boxs:hover{
    .box-img{
        display: block;
        cursor: pointer;
    }
    
}
.tab{
    display: none;
}
.message:hover{
    cursor: pointer;
}

/* MessagePopup.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .message-popup {
    border-radius: 8px;
    border: 1px solid  #FEFEFE;
    background-color:  #1F262D;
    max-width: 557px;
    width: 90%;
height: 454px;
flex-shrink: 0;
position: relative;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
  }
  .message-popup img{
    position: absolute;
    top: 15px;
    right: 14px;
    cursor: pointer;
  }
  .message-popup h3{
    color:  #EFEFEF;
font-family: Open Sans;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 184.615% */
display: flex;
width: 79%;
margin-top: 37px;
margin-bottom: 0px;
  }
  .message-popup h4{
    color:  #959595;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 184.615% */
    display: flex;
    width: 79%;
    margin-top: 0px;
margin-bottom: 17px;
  }

  .message-con{
    border-radius: 8px;
border: 1px solid  #FEFEFE;
max-width: 454px;
width: 80%;
height: 231px;
flex-shrink: 0;
display: flex;
justify-content: center;
  }
  .message-con p{
    width: 86%;
flex-shrink: 0;
color:  #FEFEFE;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 184.615% */
text-align: left;
  }

  .reply{
    border-radius: 8px;
border: 1px solid  #FEFEFE;
display: inline-flex;
height: 48px;
padding: 0px 45px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
cursor: pointer;
color:  #FEFEFE;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 36px;
  }

  .message-popup textarea{
    border-radius: 8px;
    border: 1px solid  #FEFEFE;
    max-width: 454px;
    width: 80%;
    height: 209px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    resize: none;
    padding-top: 22px;
    padding-left: 27px;
    color:  #959595;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 184.615% */
background-color:  #262626;

  }
  .publish-btn img{
    height: 14px;
    width: 14px;
  }
  
  /* Add any other styling you need */
  .future-event{
    background-color: #5c6f81;
    border-radius: 26px;
    color: white;
  }
  .no-events{
    width: 100%;
    
  }
.noe{
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    max-width: 680px;
    flex-shrink: 0;
}
@media (max-width:950px) {
    .pout{
        border: none;
    }
    .pr{
        padding-right: 0px;
    }
    .view-all{
        display: none;
    }
    .tab{
        display: flex;
width: 193px;
height: 48px;
padding: 7px 45px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid  #959595;
background-color:  #262626;
color:  #959595;
text-align: center;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 48px;
    }
    .p-mess{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .phead{
        width: 100%;
        justify-content: center;
    }
}
@media (max-width:600px) {
    .p-date{
        display: none;
    }
    .message{
        height: auto;
        min-height: 90px;
        align-items: baseline;

    }
    .mess-heading{
        padding-left: 8px;
    }
    .mess-text{
        padding-left: 8px;
        text-align: left !important;
    }
    .tab{
        width: 69%;
    }
    .p-mess{
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (max-width:350px) {
 
    .tab{
        width: 65%;
    }
}