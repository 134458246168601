.bottom1 {
}

.items-line1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-white);
    gap: 40px;
    cursor: pointer;
}

.items-line1 h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-left: 4px;
    padding-right: 4px;
}

.items-line1 h3:hover {
    background-color: var(--primary-white);
    color: var(--primary-black);
    border-radius: 4px;
}

.publihg1 {
    width: 242px;
    height: 32px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
    text-decoration: none;

}

.publihg1:hover {
    background-color: transparent;
    text-decoration: none;


    .publish-ho {
        transition: all 0.3s ease-in-out;
        content: 'Publish Your Event';
        color: #0E1111;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 242px;
        height: 32px;
        text-decoration: none;

    }
}

.publihg1 span {
    position: absolute;
    top: 8px;
    right: 24px;
    text-decoration: none;
    color:  #0E1111;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.publish-ho {
    width: 73px;
    height: 32px;
    border-radius: 8px;
    background: #FEFEFE;
    position: relative;
    cursor: pointer;
    text-decoration: none;
}

.publish-ho img {
    width: 21.333px;
    height: 20px;
    left: 27px;
    top: 8px;
    position: absolute;
}

.searches1 {
    display: flex;
    align-self: center;
    justify-content: center;
    flex-grow: 0.8;
}

.menu1 {
    display: flex;
    justify-content: center;
    align-self: center;
}

.menu1 button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    /* padding: 0px 20px; */
    padding-top: 14px;
}

a {
    text-decoration: none;
}


@media (max-width: 650px) {

    .profile-img1 {
        display: none;
    }
}

@media (max-width: 550px) {

    .items-line1 {
        display: flex;
        overflow: scroll;
        justify-content: unset;
    }

    .publihg1 {
        margin-right: 8px;
    }

    .publish-ho {
        transition: none;
    }

    .publish-ho:hover {
        transition: none;
        width: 73px;
    }

    .publihg1 span {
        display: none;
    }
}