.cont {
    /* border: 1px solid red; */
    width: 100%;
  }
  .cont1 {
    /* border: 1px solid black; */
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-left: 8px;
    margin-right: 8px;

    /* width: */
  }
  .active {
    border: 2px solid black;
  }
  
  .cont1 > div:nth-child(1) {
    width: 65%;
    border-radius: 4px;
    border: 2px solid  #FEFEFE;
    max-width: 168px;
height: 300px;
flex-shrink: 0;
  }
  
  .cont1 > div:nth-child(2) {
    width: 56%;
  }
  .box1 {
    margin-left: 50px;
  }

  
  .cont2 {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-around;
    width: 65%;
    gap: 16px;

  }
  
  .cont2 img {
    justify-content: center;
  }
  .cont2 div {
    width: 16%;
    /* border: 1px solid black; */
  }
  .cont img {
    width: 100%;
    height: 100%;
  }
  
  .footer {
    /* border:2px solid black; */
    display: flex;
    justify-content: center;
  }
  .btn {
    border: 1px solid red;
    size: 60px;
  }
  
  .eventprev{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -37px;
    left: -30%;
  }
  .eventprev img{
    height: 40px;
    width: 40px;
    }

  .eventnext{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -37px;
    right: -30%;
  }
  .eventnext img{
    height: 40px;
    width: 40px;
    }

    .eventnext,
    .eventprev {
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 24px;
  }
    
    .eventnext:hover,
    .eventprev:hover {
      transform:  scale(1.5);
      transition: transform 0.3s ease-in-out;
      color: var(--primary-white);    
    }

/* .headingName{
  display: flex;
    justify-content: center;
    align-items: center;
  position: relative;
  height: 68px;
  width: 36%;
  background-color: #c6beb3;
  bottom: 175px;

} */
.headingName h1{
  color: var(--primary-white);
}

/* .desc{
  display: flex;
    justify-content: center;
    align-items: center;
  position: relative;
  height: 50px;
  background-color: #c6beb3;
  bottom: -44px;

  } */
  .desc p{
    color: var(--primary-white);
  }

/* .dated{
  display: flex;
    justify-content: center;
    align-items: center;
  position: relative;
  height: 50px;
  background-color: #c6beb3;
  bottom: 42px;
} */
.dated p{
color: var(--primary-white);
}

.cont1 > div:nth-child(1):hover {
  /* .headingName{
    display: flex;
  } */

}
@media (max-width:450px) {
  .cont1{
flex-direction: column;
align-items: center;

  }
  .cont1 > div:nth-child(1){
max-width: 450px;
width: 90%;
  }
  .cont1 > div:nth-child(2) {
    width: 90%;
  }
}