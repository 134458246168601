/* SignupPopUp.css */
.signup-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 100%;
    height: auto;
    /* max-height: 462px; */
    /* Limit the maximum height */
    background: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
}
.signup-popup1 {
    display: contents;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: auto;
    /* max-height: 462px; */
    /* Limit the maximum height */
    background: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
}



.signup-popup p {
    margin: 10px 0;
}

.started {
    color: #0E1111;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
}

.signin-option{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 9px;
}

.email1,
.google1 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 370px !important;
    height: 50px !important;
    border-radius: 8px !important;
    background: var(--primary-black) !important;
    color: #FEFEFE !important;
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    gap: 12px !important;
    cursor: pointer !important;
}
.google1 div{
    margin-right: 0 !important;
    background-color: transparent !important;
    padding: 0 !important;

}

.email {
    margin-top: 9px;
}

.or {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 23px;
    margin-bottom: 23px;
}

.para {
    width: 26px;
    height: 17px;
    flex-shrink: 0;
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 91.667% */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
}

.mobile {
    height: 50px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send {
  padding: 0px;
  margin-left: 8px;
  display: flex;
  justify-content: center;

}
.privacy{
    margin-top: 68px;
    color: #000;
font-family: Open Sans;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px;
}
.inner{
    width: 310px;
height: 50px;
border-radius: 8px;
border: 2px solid #000;
display: flex;
justify-content: flex-start;
align-self: center;
padding-right: 8px;
}
.ind{
    color: #000;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 22px;
display: flex;
justify-content: center;
align-self: center;
border-right: 1px saddlebrown;
margin-left: 8px;
flex-shrink: 0;

}

.inner input{
    color: #000;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
border: none;
width: 76%;
margin-left: 8px;

}

.inner input:active{
    border: none;
}
textarea:focus, input:focus{
    outline: 0;
}

.google{
    display: flex;
    flex-direction: column;
}

.name input,
.password input,
.confirmpass input
{
    width: 370px;
height: 50px;
flex-shrink: 0;
border-radius: 8px;
border: 2px solid #000;
margin: 3.5px;
padding-left: 26px;

}
.general input{
    width: 370px;
height: 50px;
flex-shrink: 0;
border-radius: 8px;
border: 2px solid #000;
margin: 3.5px;
padding: 0px;
padding-left: 26px;
font-size: 13px;

}
.signup-btn{
    display: inline-flex;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color:  #1F262D;
margin-top: 32px;
padding: 15px 21px 15px 21px;


}
.new{
    margin-top: 72px;
    display: flex;
    justify-content: center;

}
.newto{
    color:  #262626;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
border: 1px solid  #0E1111;
padding-left: 8px;
padding-right: 8px;
cursor: pointer;

}
.new span:hover{
    background-color: var(--primary-black);
    color: var(--primary-white);
    .newto b{
        color: var(--primary-white);

    }
}
.newto b {
    color:  #262626;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 22px;
cursor: pointer;
}
.emailprivacy{
    margin-top: 24px;
}
.Enter-otp{
    color: var(--primary-black, #0E1111);
/* Heading 2 - 40px semi-bold */
font-family: Montserrat;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 48px;
margin-top: 80px;
margin-bottom: 59px;
}
.resend{
    font-weight: 700;
    cursor: pointer;
}
.outer{
    width: 310px;
height: 80px;
border-radius: 8px;
background:  #262626;
display: flex;
justify-content: center;

}
.otp-screen{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.line{
    max-width: 380px;
    width: 100%;
flex-shrink: 0;
color: #000;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */
margin-top: 23px;
}
.proceed{
    display: inline-flex;
/* height: 32px; */
padding: 15px 14px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 4px;
border: 1px solid  #FEFEFE;
background-color:  #1F262D;
margin-top: 67px;
}
.resetpass{
    color:  #262626;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
display: flex;
margin-top: 8px;
cursor: pointer;
width:70%;
}
.reset-email{
    
flex-shrink: 0;
color: #262626;
text-align: center;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 22px;
margin-top: 80px;
margin-bottom: 32px;
width: 279px;
height: 60px;
}

.reset-email-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.reset-paragraph{
    width: 279px;
height: 60px;
flex-shrink: 0;
color:  #262626;
text-align: center;
font-family: var(--font-montserrat);
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 22px;
}
.signup-btn-div{

}
.errorMessage{
    flex-shrink: 0;
color: #ca0000;
text-align: center;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
margin-top: 4px;
margin-bottom: 4px;
}
.errored input{
    border: 2px solid red; ;
}
@media (max-width:620px) {
    .signup-popup{
        width: 85%;
    }
}
@media (max-width:486px) {
    .name{
        width: 100%;
    }
    .name input{
        width: 90%;
    }

    .password{
        width: 100%;
    }
    .password input{
        width: 90%;
    }
    .confirmpass{
        width: 100%;
    }
    .confirmpass input{
        width: 90%;
    }

    .general{
        width: 100%;
    }
    .general input{
        width: 90%;
    }
    .outer{
        width: 90%;
        padding: 4px;
    }
    .outer > div{
        width: 100%;
        justify-content: center;

    }
    .line{
        width: 90%;
    }
}