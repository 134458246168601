* {
    --lineHeight: 2px;
    /* box-sizing: border-box; */
}

.container {
    text-align: center;
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    width: 90%;
    align-items: center;

}

.circles-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1120px;
    width: 100%;
}

.circle {
    background: rgba(0, 0, 0, 0.75);
    ;
    color: white;
    border-radius: 50%;
    border: 2px solid #FEFEFE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    font-size: 1.5rem;
    width: 35px;
    z-index: 2;
    margin: 0 -2px;
    transition: 1s ease;
}

.circle.active {
    border-color: #FEFEFE;
    background-color: #FEFEFE !important;
}

.progress-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-bottom: 113px;
    max-width: 1120px;
    width: 100%;
    align-items: center;

}

.progress-container::before {
    content: "";
    background: #dbdbdb;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: var(--lineHeight);
    width: 100%;
    z-index: -1;
    transition: 1s ease;
}

.progress {
    background: #FEFEFE;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: var(--lineHeight);
    width: 0%;
    z-index: 1;
    transition: 0.4s ease;
    max-width: 100% !important;
}

.btn {
    color: #1F262D;

    /* Body Text 2 - 16px Medium */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    border-radius: 8px;
    border: 1px solid #1F262D;
    background: #EFEFEF;
    border-radius: 8px;
    display: flex;
    padding: 12px 25px;
    justify-content: center;
    align-items: center;
    gap: 8px;

}

.btn:focus {
    outline: 0;
}

.btn:active {
    transform: scale(0.97);
}

.btn:disabled {
    display: none;
}

.progress-container ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
}

.progress-container ul li {
    display: inline-block;
    list-style: none;
    /* width: 100px;*/
    text-decoration: none;
    overflow: hidden;
}

.tags {
    display: flex;
    max-width: 1120px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px;
}

.tag-name {
    color: #959595;
    text-align: center;

    /* Body 1 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;
}

.tag-name.active {
    color: #FEFEFE;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;
}

.next-pro {
    display: flex;
    gap: 13px;
    margin-top: 80px;
    margin-bottom: 172px;
}

.btn:hover {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background-color: #1F262D;
}

.main-publish {
    max-width: 1120px;
    width: 100%;
    min-height: 600px;
    flex-shrink: 0;
    border-radius: 16px;
    background-color: #1F262D;
    position: relative;
}

.event-d {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-d h2 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 75% */
    min-width: 222px;
    height: 23px;
    flex-shrink: 0;
    margin-top: 86px;
    margin-bottom: 66px;
}

.event-tittles {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;

}

.event-tittles input {
    width: 926px;
    width: 80%;
    height: 48px;
    color: #959595;
    /* Body 1 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: #262626;
    padding-left: 16px;

}
.event-tittles textarea {
    width: 926px;
    width: 80%;
    height: 48px;
    color: #959595;
    /* Body 1 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    /* 140% */
    letter-spacing: 0.08px;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: #262626;
    padding-left: 16px;
    padding-top: 16px;

}

.event-tittles h3 {
    color: #959595;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 92.308% */
    letter-spacing: 0.195px;
    margin-top: 8px;
    margin-bottom: 0px;
    width: 77%;
    display: flex;

}

.drop-boxs {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    width: 80%;
    color:  #959595;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 12px; /* 75% */
letter-spacing: 0.24px;
padding-bottom: 28px;
row-gap: 28px;

}

.date-pic {
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: #262626;
    width: 280px;
    height: 48px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    /* margin-bottom: 28px; */

}
.root-98{
    width: 90%;
}
.root-98 div{
background-color:  #262626;
color:  #959595;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 12px; /* 75% */
letter-spacing: 0.24px;
border: none;
display: flex;
justify-content: flex-start;
align-items: center;
width: 100%;
}

.root-135,
.monthPickerWrapper-132 {
    background-color: var(--Dark-blue);
}

.content-123 button,
.monthAndYear-137,
.weekDayLabelCell-149,
.dayButton-153 span {
    color: #FEFEFE;
}
.upload{
    color: var(--primary-black, #0E1111);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color:  #FEFEFE;
display: inline-flex;
height: 32px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
}
.text1{
    color:  #959595;

/* Body 1 */
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22.4px; /* 140% */
letter-spacing: 0.08px;
margin-top: 0px;
}
.event-tittles1{
    width: 80%;
    height: 48px;
    border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color:  #262626;
display: flex;
flex-direction: row;
justify-content: space-between;
padding-right: 16px;
padding-left: 16px;
margin-bottom: 0px;

}
.event-tittles1 button{
    color:  #0E1111;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 8px;
}
.event-tittles1 button:hover{
background-color: var(--primary-black);
color: var(--primary-white);
border: 1px solid  #FEFEFE;
}

.event-d p{
    display: flex;
max-width: 925px;
min-height: 54px;
flex-direction: column;
justify-content: center;
color:  #959595;
text-align: center;
/* Body 1 */
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22.4px; /* 140% */
letter-spacing: 0.08px;
margin-bottom: 72px;
}
.event-cover{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
border: 1px solid #FEFEFE;
max-width: 972px;
min-height: 527px;
flex-shrink: 0;
margin-bottom: 24px;
position: relative;
}
.add-more{
    border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color:  #FEFEFE;
display: inline-flex;
height: 42px;
padding: 7px 45px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
margin-top: 49px;
margin-bottom: 66px;
}
.big-box textarea{
    height: 100px;
    resize: none;
}
.covers{
    height: 361px;
    padding-top: 28px;
}
.tremsc{
    min-height: 300px;
}
.gcover{
    min-height: 187px;
}
.class{
    background-color: transparent;
    min-height: auto;
}
.hidden{
    display: none;
}
.well-done{
    max-width: 457px;
    height: 71px !important;
    color: #FFF !important;
    text-align: center !important;
    font-family: Montserrat !important;
    font-size: 32px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 40px !important;
    margin-bottom: 37px;/* 125% */
}
.remove-insight{
    position: absolute;
    top: 14px;
    right: 15px;
    cursor: pointer;
}
.relative{
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: -192px;
}
.promo{
    color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 75% */
margin-bottom: 48px;
}
.promoc{
    margin-top: 150px;
}
.pcover{
    align-items: flex-start;
}
.drops{
    border-radius: 8px;
border: 1px solid  #959595;
max-width: 788px;
width: 100%;
height: 134px;
flex-shrink: 0;
margin-bottom: 28px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.drops h2{
    margin-top: 0px;
    margin-bottom: 24px;
    color:  #959595;
text-align: center;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22.4px; /* 140% */
letter-spacing: 0.08px;
width: 90%;
display: flex;
}
.pickers{
    display: flex;
    gap: 25px;
}
.pickers h2{
    margin-top: 0px;
    margin-bottom: 0px;
}
.datepicker{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.datepicker input{
    color:  #959595;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 75% */
    letter-spacing: 0.24px;
    border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color:  #262626;
width: 256px;
height: 48px;
flex-shrink: 0;
}

.datepicker h3{
margin-top: 0px;
margin-bottom: 0px;
color:  #959595;

/* Label */
font-family: Open Sans;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 12px; /* 120% */
letter-spacing: 0.15px;
width: 90%;
display: flex;
}
.titt{
    flex-direction: row;
    width: 80%;
    justify-content: start;
    gap: 51px;

}
.full{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.full h3{
    color: #959595;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.195px;
    margin-top: 8px;
    margin-bottom: 0px;
    display: flex;
}
.arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #FEFEFE;
    cursor: pointer;
    color: #FEFEFE;
  }
  .dropdown-container {
    position: relative;
  }
  .dropdown-input {
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: #262626;
    width: 280px;
    height: 48px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
    padding-left: 12px;
    align-items: center;
  }
  .dropdown-placeholder {
    color: dimgrey;
  }
  .dropdown-values {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 6px;
  }
  .dropdown-value {
    color: black;
    padding: 4px;
    background-color: darkgray;
    border-radius: 5px;
    margin-right: 6px;
    animation: expand 0.1s ease-in-out;
  }
  .dropdown-remove {
    cursor: pointer;
  }
  .dropdown-options {
    visibility: hidden;
    position: absolute;
    top: 100%;
    opacity: 0;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
  }
  .dropdown-active {
    visibility: visible;
    opacity: 10;
    border: 1px solid var(--primary-white);
    border-radius: 8px;
    z-index: 1;

  }
  .dropdown-item {
    width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--Dark-blue);
    color: var(--primary-white);
    padding: 13px 19px;
    border-bottom: 1px solid grey;
    border-radius: 5px;
  }
  .dropdown-item:hover {
    background-color: var(--primary-black);
    color: var(--primary-white);
  }
  .dropdown-item img {
    width: 30px;
    margin-right: 20px;
  }
  .text2{
    display: none;
  }
  
  @keyframes expand {
    from {
      transform: scale(0);
      opacity: 0;
    }
  }
  
  @media (max-width:1066px) {
    .pickers{
        flex-direction: column;
    }
}
  @media (max-width:950px) {
    .tag-name{
        transform: rotate(-90deg);
    }
    .tags{
        margin-bottom: 60px;
        justify-content: space-between;
    }
   
    .drops{
        height: auto;
        padding-top: 45px;
        padding-bottom: 32px;
    }
    .drops h2{
        align-items: center;
        justify-content: center;
    }
    .titt{
        flex-wrap: wrap;
    }
  }
  @media (max-width:700px) {
   
     .promoc{
        margin-top: 215px;
     }
     .relative{
        top: -258px;
     }
  }
  @media (max-width:600px) {
   
    .tags{
        display: none;
    }
    .text1{
        display: none;
    }
    .text2{
        display: flex;
    }
    .event-d p{
        width: 90%;
    }
  }

  @media (max-width:350px) {
   
    .date-pic,
    .dropdown-container,
    .datepicker input{
        width: 100%;
    }
    .datepicker input{
        padding-left: 4px;
        padding-right: 4px;
    }
    .dropdown-input{
        width: 100%;
    }
  }