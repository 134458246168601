.custom-section {
    width: 90%;
    margin-top: 64px;
    
}

.section-heading {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;

}

.section-line {
    display: block;
    margin: 0 auto;
    /* Center the line image horizontally */
    margin-bottom: 48px;
}

.see-more-link {
    color:  #959595;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
height: 48px;
cursor: pointer;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 24px;
}
.see-more-link a{
    text-decoration: none;
    color:  #959595;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
cursor: pointer;

}
.see-more-link a:hover{
color: var(--primary-white);
background-color: var(--primary-black);
}