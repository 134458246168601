.cont {
    /* border: 1px solid red; */
    width: 100%;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cont1 {
    /* border: 1px solid black; */
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
    width: 100%;
  }
  .active {
    border: 2px solid black;
  }
  
  .cont1 > div:nth-child(1) {
    width: 80%;
    max-width: 1080px;
    border: 1px solid  #FEFEFE;
    position: relative;


  }
  
  .cont1 > div:nth-child(2) {
    width: 30%;
  }
  .box1 {
    margin-left: 50px;
  }

  
  .cont2 {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-around;
    width: 65%;
    gap: 16px;

  }
  
  .cont2 img {
    justify-content: center;
  }
  .cont2 div {
    width: 16%;
    /* border: 1px solid black; */
  }
  .cont img {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
  
  .footer {
    /* border:2px solid black; */
    display: flex;
    justify-content: center;
  }
  .btn {
    border: 1px solid red;
    size: 60px;
  }
  
  .eventprev{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .eventprev img{
    height: 40px;
    width: 40px;
    }

  .eventnext{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .eventnext img{
    height: 40px;
    width: 40px;
    }

    .eventnext,
    .eventprev {
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 24px;
  }
    
    .eventnext:hover,
    .eventprev:hover {
      transform:  scale(1.5);
      transition: transform 0.3s ease-in-out;
      color: var(--primary-white);    
    }

    .paragraphs{
      position: absolute;
  display: flex;
  flex-direction: column;
  gap: 12px;
  top: 37px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }

.headingName{
  display: flex;
  opacity: 0.8;
  background-color:  #262626;
  max-width: 418px;
height: 56px;
width: 100%;
align-items: center;

}
.headingName h1{
  color: var(--primary-white);
  color: #FFF;
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 42px; /* 131.25% */
margin-top: 0px;
margin-bottom: 0px;
padding-left: 38px;
}

.desc{
  max-width: 418px;
  height: 56px;
  flex-shrink: 0;
  opacity: 0.8;
background-color:  #262626;
width: 100%;
align-items: center;

  }
  .desc p{
    color:  #EFEFEF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */  
    padding-left: 38px;
    margin-top: 0px;
margin-bottom: 0px;
text-align: initial;


  }

.dated{
  display: flex;
  justify-content: flex-start;
  align-items: center;
    max-width: 174px;
    height: 27px;
    flex-shrink: 0;
    opacity: 0.8;
background-color:  #262626;
align-items: center;

}
.dated p{
  color:  #FEFEFE;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  padding-left: 38px;
  margin-top: 0px;
margin-bottom: 0px;
text-align: initial;

   /* 323.077% */}

/* .cont1 > div:nth-child(1):hover { */
  .paragraphs{
    opacity: 1;
    visibility: visible;

  }

/* } */
@media (max-width:769px) {
  .headingName h1{
    font-size: 24px;
    padding-left: 15px;
    font-weight: 400;
  }
  .desc p{
    font-size: 14px;
    padding-left: 15px;
  }
  .dated p{
    padding-left: 15px;
    font-size: 14px;
    font-weight: 200;

  }
  .headingName
  ,.dated,
  .desc{
    width: 90%;
    height: auto;
  }
}
@media (max-width:510px) {
  .headingName h1{
    font-size: 18px;
    padding-left: 8px;
    font-weight: 400;
    line-height: 32px;
  }
  .desc p{
    font-size: 12px;
    padding-left: 8px;
  }
  .dated p{
    padding-left: 8px;
    font-size: 10px;
    font-weight: 200;
    line-height: 23px;

  }
  .headingName
  ,.dated,
  .desc{
    width: 90%;
    height: auto;
  }
  .paragraphs{
    top: 8px;
    gap: 8px;
  }
}

@media (max-width:350px) {
  .headingName h1{
    font-size: 16px;
    padding-left: 6px;
    font-weight: 300;
    line-height: 30px;
  }
  .desc p{
    font-size: 10px;
    padding-left: 6px;
  }
  .dated p{
    padding-left: 6px;
    font-size: 9px;
    font-weight: 200;
    line-height: 20px;

  }

}
