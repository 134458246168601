.allRightsReservedMadeWithParent {
  display: flex;
  flex-direction: row;
  align-self: center;
  min-height: 64px;
  background-color: #0E1111;
  color: #FEFEFE;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  align-items: center;
width: 100%;
justify-content: space-between;
}

.upper {
  min-height: 241px;
  display: flex;
  flex-direction: row;
  background-color: var(--Dark-blue);
  align-items: center;
  gap: 40px;
  text-align: start;

}

.publishYourEventParent,
.faqsParent,
.aboutUsParent {
  color: #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-left: 80px;

}

.publishYourEventParent div,
.faqsParent div,
.aboutUsParent div {
  margin-top: 8px;

}

.madeby {
  padding-left: 16px;
  font-size: 12px;

}

.social-icons {
  display: flex;
  gap: 16px;

}

.mainlogo {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.two {
  display: flex;
  gap: 16px;
  width: 50%;
  justify-content: space-around;
}

.three {
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
}
.icons{
  display: flex;
  /* flex-grow: 1; */
  justify-content: flex-end;
  padding-right: 32px;

}
.faqsParent{
  
  /* width: 202px; */
  height: 160px;

}

.hover{
  cursor: pointer;

}
.hover:hover{
  font-weight: 600;
}
@media (max-width:930px) {
  .two {
    flex-direction: column;
  }

  .three {
    flex-direction: column;
  }

  .upper {
    gap: 32px;
  }

  .mainlogo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .faqsParent{
  
    width: auto;
    height: auto;
  
  }
    .logo {
      height: 125px;
      width: 125px;
  }
  

}

@media (max-width:500px) {
  .two {
    flex-direction: column;
  }

  .three {
    flex-direction: column;
  }

  .upper {
    flex-direction: column;
    gap: 16px;
  }

  .mainlogo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 16px;
  }
  .logo{
    height: 100px;
    width: 100px;
  }
  .allRightsReservedMadeWithParent{
    flex-direction: column;
    gap: 8px;
  }
  .faqsParent{
  
    width: 100%;
  
  }
  .icons{
    padding-right: 0px;
  }
  .publishYourEventParent,
.faqsParent,
.aboutUsParent {
 
  padding-left: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

}

}