.time-picker {
    margin-top: 1rem;
    color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: center;
    justify-content: end;
    padding-right: 54px;
}
.time-picker > div {
    background: var(--Dark-blue);
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.top-menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 0.5rem 0 1rem 0;
    padding: 0 0.5rem;
    font-weight: bold;
    color:  #CAC4D0;
font-family: Open Sans;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 88.889% */
letter-spacing: 0.08px;
}
/* button {
    border-radius: 0.25rem;
    padding: 0.2rem 0.5rem;
    background: rgb(47, 49, 54);
    border: none;
    font-family: inherit;
    font-weight: bold;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.selector-list {
    display: flex;
}
.month,
.year {
    font-weight: bold;
    min-width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
}
.selector button {
    padding: 0.25rem 0.5rem;
    display: flex;
width: 38.4px;
height: 38.4px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 8px;
background-color: var(--Dark-blue);
}
.flex {
    display: flex;
}
.row {
    flex-direction: row;
}
.picked-date {
    color: var(--m-3-sys-dark-on-surface, #E6E0E9);
font-family: Open Sans;
font-size: 22.154px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 144.444% */
}
.buttons{
    display: flex;
    justify-content: end;
    width: 100%;
    gap: 16px;
    padding-right: 24px;
    padding-top: 12px;
    cursor: pointer;
}
.cancel,
.ok{
    color:  #D0BCFF;
text-align: center;

/* M3/label/large */
font-family: Open Sans;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
letter-spacing: 0.1px;
}
.cancel:hover,
.ok:hover
{
    background-color: var(--primary-white);
    color: var(--primary-black);
}
@media (max-width:450px) {
    .selector-list{
        flex-direction: column;
    }
    .time-picker{
        justify-content: center;
        padding-right: 0px;
    }
}
@media (max-width:1000px) {

    .time-picker{
        justify-content: center;
        padding-right: 0px;
    }
}