.upper-access{
    display: flex;
    width: 70%;
    justify-content: space-between;
    margin-bottom: 24px;
}

.accessyour{
    max-width: 324px;
    width: 100%;
height: 30px;
flex-shrink: 0;
color:  #FEFEFE;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
text-align: left;
}
.downloadticekt{
    color:  #FEFEFE;
    text-align: right;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    max-width: 174px;
    width: 100%;
display: flex;
gap: 8px;
}
.downloadticekt{
    cursor: pointer;
}
.yourticket{
    display: flex;
    width: 90%;
    justify-content: center;
    max-width: 1080px;
}
.left-tic{
    max-width: 671px;
    width: 100%;
    height: 464px;
    flex-shrink: 0;
    background: #1F262D;
    max-width: 671px;
    width: 100%;
    height: 464px;
    flex-shrink: 0;
    background: #1F262D;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.right-tic{
    border-left: 1px dashed #7C92A9;
    background: #2C353E;
    max-width: 409px;
    width: 100%;
height: 464px;
flex-shrink: 0;
display: flex;
align-items: center;
flex-direction: column;
justify-content: flex-start;
overflow-y: scroll;

}
.ticketimage{
    border-radius: 4px;
border: 1px solid  #FEFEFE;
max-width: 190px;
width: 100%;
height: 286px;
flex-shrink: 0;
object-fit: cover;
}
.ticket-upper{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 24px;
    margin-bottom: 20px;
}
.ticket-titlte{
    color:  #FEFEFE;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 42px; /* 175% */
display: flex;
margin-bottom: 20px;
text-align: left;
}
.ticket-address{
    display: inline-flex;
align-items: center;
gap: 8px;
color:  #EFEFEF;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
text-align: left;
margin-bottom: 40px;
}
.ticket-booking{
    display: flex;
    width: 90%;
    gap: 70px;
}

.ticekt-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;

}
.book-detailss{
display: flex;
flex-direction: column;
text-align: left;

}
.book-languagess{
    color:  #FEFEFE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.event-datess{
    color:  #FEFEFE;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}
.event-timesss{
    color:  #FEFEFE;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 600;
}
.booking-idss{
    color:  #FEFEFE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.ticket-frame{
    display: inline-flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 17px;
margin-top: 48px;
margin-bottom: 20px;
}
.qr-wrapper{
    display: flex;
align-items: flex-start;
gap: 17px;
}
.qr-code{
    width: 100px;
    height: 100px;
}
.book-detailssss{
    display: flex;
height: 85px;
flex-direction: column;
align-items: flex-start;
gap: 16px;

}
.ticket-names{
    color:  #FEFEFE;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 120% */
max-width: 201px;
width: 100%;
height: 11px;
flex-shrink: 0;
}
.ticket-no{
    color:  #FEFEFE;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}
.viewss{
    border-radius: 4px;
background: var(--System-Colors-black-blue, #1F262D);
display: flex;
max-width: 110px;
width: 100%;
height: 32px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
color:  #FEFEFE;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
cursor: pointer;
}
.tickets-id{
    color:  #FEFEFE;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 120% */
max-width: 185px;
width: 100%;
text-align: left;

}
.qr-codes{
    margin-top: 0;
}
.tic-date{
    color:  #EFEFEF;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 42px; /* 210% */
}
.popup-content4{
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 3;
    background: transparent;
    max-width: 1180px;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.close-button3{
    position: absolute;
    bottom: 53px;
    right: 75px;
    border-radius: 6px;
    background: #2C353E;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    color: #FEFEFE;
    flex-shrink: 0;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    cursor: pointer;
    border-radius: 6px;
background:  #0d1114;
display: flex;
max-width: 165px;
width: 100%;
height: 48px;
padding: 25.5px 54px;
justify-content: center;
align-items: center;
gap: 12px;
flex-shrink: 0;
}
.example{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
@media (max-width:1100px) {
    .yourticket{
        flex-direction: column;
        align-items: center;

    }
    .right-tic{
        flex-direction: row;
        max-width: 671px;
        justify-content: space-around;

    }
    .upper-access{
        width: 84%;
    }
}
@media (max-width:790px) {
   
}
@media (max-width:590px) {

    .right-tic{
       flex-direction: column;

    }
    .upper-access{
        flex-direction: column;
        gap: 18px;
        align-items: center;
        }
        .accessyour{
            height: auto;
        }

}
@media (max-width:500px) {

   .ticket-upper{
    flex-direction: column;
    align-items: center;
   }
   .left-tic{
    height: auto;
    padding-bottom: 20px;
    padding-top: 20px;
   }
   .bottomleft{
    width: 85%;
   }

}
