.events-div {
    background-color: var(--primary-black);
    background-image: url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\1.svg'), url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\3.svg');
    background-repeat: no-repeat;
    background-position: top left, right bottom;
    width: 100%;
}

.main-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.line-head {
    justify-content: center;
    align-items: center;
    width: 100%;
}

.event-heading {
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 100% */
    margin-top: 82px;
}

.filter-cards {
    width: 100%;
    display: flex;
    gap: 56px;
}

.filter {
    display: flex;
    justify-content: flex-start;
    align-items: end;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    max-width: 30%;
    margin-bottom: 48px;

}




.calender {
    margin-left: 35px;
}

.languages {
    width: 273px;
    height: 170px;
    background-color: var(--Dark-blue);
    margin-top: 12px;
    margin-left: 80px;
    overflow-y: scroll;
    border-radius: 8px;
    border: 0.688px solid #FFF;

}

.langu {
    color: #959595;
    height: 32px;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    border-radius: 8px 8px 0px 0px;
    border: 0.688px solid #FFF;
    background: #262626;
    position: sticky;
    top: 0px;

}

.language-item {
    border: 0.688px solid #FFF;
    border-left: none;
    border-radius: none;
    background: var(--rare-black, #1F262D);
    height: 34px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    padding-left: 16px;
    align-items: center;
    width: 94%;
    gap: 8px;

}

.select-language {
    color: #FEFEFE;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.discount {
    width: 273px;
    height: 163px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background: #1F262D;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.discount-head {
    color: #DCDCDC;

    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 160% */
    border-bottom: 1px solid #FEFEFE;
    width: 92%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 22px;
}

.discount-slider {
    width: 85%;
    /* flex-grow: 1; */

}

.view-sold {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 276px;
    height: 100px;
    border-radius: 8px;
}

.latest,
.most-viewed,
.most-sold {
    border: 0.688px solid #FEFEFE;
    background-color: #0E1111;
    color: #FEFEFE;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 33px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 24px;
}

.latest {
    border-radius: 8px 8px 0px 0px;

}

.most-sold {
    border-radius: 0px 0px 8px 8px;
}

.selected1 {
    border: 0.688px solid #FEFEFE;
    background-color: var(--Dark-blue);
    color: #FEFEFE;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 33px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 24px;
}

.price-range {
    color: #DCDCDC;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 200% */
    width: 84%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 26px;
    margin-bottom: 4px;
}

.ranges {
    display: flex;
    justify-content: space-between;

}

.ranges p {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #DCDCDC;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 266.667% */
}

.subcategory {
    width: 1119px;
    min-height: 80px;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 84%;
    padding: 16px;
    flex-wrap: wrap;
    margin-bottom: 54px;

}

.sub-category {
    display: flex;
    height: 32px;
    padding: 0px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    color: #FFF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.selected-subcategory {
    background-color: var(--primary-white);
    color: var(--primary-black);
}

.tab-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 8px;
    display: none;
}

.tab-filters div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    color: #DCDCDC;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 200% */
    cursor: pointer;
}

.date-filter {
    width: 104px;
    height: 34px;
}

.language-filter {
    display: flex;
    width: 145px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.discount-filter {
    display: flex;
    width: 130px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.price-filter {
    display: flex;
    width: 98px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.view-filter {
    display: flex;
    width: 158px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.filter-dropdown {
    position: absolute;
    z-index: 1000;
}

.mobile-filter {
    width: 90%;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    display: flex;
    align-items: center;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 200% */
    margin-bottom: 64px;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    /* Remove default checkbox styles (for WebKit browsers) */
    -moz-appearance: none;
    /* Remove default checkbox styles (for Firefox) */
    appearance: none;
    /* Remove default checkbox styles (for modern browsers) */
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    /* Add a border to the checkbox for better visibility */
    border-radius: 3px;
    /* Add rounded corners to the checkbox */
    outline: none;
    /* Remove the default focus outline */
    cursor: pointer;
    /* Change cursor to pointer on hover */
}

/* Style the custom checkbox */
input[type="checkbox"]::before {
    content: '\02714';
    /* Unicode checkmark symbol */
    display: block;
    /* Display the pseudo-element as a block element */
    width: 100%;
    /* Set the width of the pseudo-element */
    height: 100%;
    /* Set the height of the pseudo-element */
    text-align: center;
    /* Center the checkmark horizontally */
    line-height: 16px;
    /* Vertically center the checkmark */
    background-color: transparent;
    /* Set the background color of the pseudo-element to transparent */
    border: 1px solid #ccc;
    /* Add a border to the pseudo-element for better visibility */
    border-radius: 3px;
    /* Add rounded corners to the pseudo-element */
    transition: background-color 0.3s, color 0.3s;
    /* Add transition effects for smooth hover and check/uncheck */
    color: transparent;
    /* Hide the checkmark by default */
}

/* Change the background color to white when checked */
input[type="checkbox"]:checked::before {
    background-color: #fff;
    /* Set the background color to white when checked */
    color: #000;
    /* Set the color to black for the checkmark when checked */
    border: 1px solid #000;
}
.filter-mobile-cover{
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.event-mobile-filter1 {
    height: 48px;
    width: 90%;
    display: flex;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;

    /* 200% */
}

.event-mobile-filter {
    width: 100% !important;
    display: flex;
    background-color: #1F262D;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    align-items: center;
    justify-content: space-between;

    flex-direction: column;

    /* 200% */
}
.filter-img{
    width: 10.002px;
height: 10.002px;
}
.filter-options{
    width: 100%;
    margin-top: 56px;
}
.filter-name{
    width: 100%;
    border-top:  1px solid #959595;
    border-bottom:  1px solid #959595;
    display: flex;
    align-items: center;
    gap: 8px;

}
.fname{
    margin-left: 12px;
    margin-left: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
}
/* -----------------------------------=----------------Cards section------------------------------------------------------ */
.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    row-gap: 0px;

}

.cards {
    flex: 3;
    max-width: 67%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* display: grid; */
    position: relative;
}

.carousel-card2.active {
    transform: translateX(0);
    opacity: 1;
}

.image1 {
    flex: 1;
    overflow: hidden;
    margin: 0;
    padding: 0;
    min-width: 252px;
    min-height: 247.08px;
display: flex;
}

.image1 img {
    width: 100%;
    max-height: 252px;
    object-fit: cover;
    border-radius: 16px 16px 0px 0px;
    display: block;
    /* Make sure the image is treated as a block element */
    width: 100%;
    /* Ensure the image takes the full width of its parent div */
    height: auto;

}

.details1 {
    text-align: start;
    background-color: var(--primary-black);
}

.tittle1 {
    color: var(--primary-white);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    height: 32px;
    background-color: var(--Dark-blue);
    padding-left: 8px;
    margin-right: 0;
}

.date1,
.location1 {
    color: #EFEFEF;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.price1 {
    display: inline-block;
    height: 24px;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 150px;
    background: #262626;
    color: var(--hint-text, #959595);
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 8px;
    margin-left: 8px;
}
.mobile-open{
    background-color: rgba(38, 38, 38, 1);
}
.icon1 {
    padding-left: 8px;
    padding-right: 8px;
}

.icon-text1 {
    padding-top: 8px;
}

.available {
    display: none;

}





.carousel-card2:hover {
    transform: scaleY(1.02);
    transition: translate 0.3s ease-in-out;
    translate: 0px -10px 0px;
    box-shadow: 0px -70px 150px 0px #404040;



    .available {
        display: block;
        display: inline-flex;
        padding: 2px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 150px;
        background-color: var(--primary-white);
        position: absolute;
        right: 7px;
        top: 7px;

        color: #0E1111;

        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
    }

    .price1 {
        background-color: var(--primary-white);
        color: var(--primary-black);
    }
}

.availability-status-red {
    display: inline-flex;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 150px;
    background-color: #CA0B4A;
    position: absolute;
    right: 7px;
    top: 7px;
    color: #FEFEFE;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.availability-status-yellow {
    display: inline-flex;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 150px;
    background: #FD5;
    position: absolute;
    right: 7px;
    top: 7px;

    color: #0E1111;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 169.231% */
}




.carousel-card2 {
    border-radius: 16px 16px 0px 0px;
    margin: 0 8px;
    max-width: 252px;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    position: relative;
    transform-origin: bottom;
    position: relative;
    box-shadow: none;
    transition: box-shadow 0.3s ease-in-out;
    background: linear-gradient(0deg, var(--primary-black) 0%, #eeeeee 100%);
    border-radius: 16px;
    padding: 1px;
    margin: 0 8px;
    height: 386px;
    flex-basis: calc(33.33% - 16px);
    /* Initially, 3 cards in a row */
    text-decoration: none;

}
.No-event{
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    max-width: 680px;
    flex-shrink: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 8%;
}

@media (max-width: 992px) {
    .carousel-card2 {
        flex-basis: calc(50% - 16px);
        /* 2 cards in a row */
    }
}

/* Media query for even smaller screens (1 card in a row) */
@media (max-width: 768px) {
    .carousel-card2 {
        flex-basis: 100%;
        /* 1 card in a row */
    }
}

@media (max-width:1000px) {
    .tab-filters {
        display: flex;
        margin-bottom: 100px;
    }

    .filter {
        display: none;
    }

    .filter-cards {
        justify-content: center;
    }
    .cards{
        justify-content: center;
        max-width: 94%;
        row-gap: 48px;
    margin-bottom: 20px;
    min-height: 210px;

    }
}

@media (max-width:525px) {
    .tab-filters {
        display: none !important;
    }
    .filter-mobile-cover{
        display: flex;
        margin-bottom: 56px;

    }
    .calender{
        margin-left: 0px;
    }
    .time-picker > div{
        margin: 0px;
        padding: 0px;
    }
    .languages{
        margin: 0px;
        width: 100%;
        border-radius: 0px;
        border-top:none ;
        border-bottom:none ;
    }
    .discount{
        width: 100%;
        border-radius: 0px;
    }
    .language-item{
        width: 96%;
    }
    .view-sold{
        width: 100%;
    }
}
    
