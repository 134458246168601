.number-popup{
    max-width: 475px;
    width: 100%;
height: 350px;
flex-shrink: 0;
border-radius: 16px;
background-color: #FAFAFA;
display: flex;
justify-content: center;
align-items: baseline;
}
.we{
    color: #000;
text-align: center;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 157.143% */
max-width: 298px;
margin-top: 76px;
margin-bottom: 30px;

}
.numbers input{
    color:  #959595;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
border-radius: 8px;
border: 2px solid #000;
max-width: 370px;
width: 90%;
height: 50px;
flex-shrink: 0;
padding-left: 16px;
}
.numbers button{
    display: inline-flex;
height: 32px;
padding: 10px 14px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 4px;
border: 1px solid  #FEFEFE;
background:  #1F262D;
}
.btn4{
    display: flex;
    gap: 6px;
    width: 100%;
    justify-content: center;
    margin-top: 24px;

}
.proceeds{
    border-radius: 4px;
border: 1px solid  #FEFEFE;
background:  #1F262D;
display: inline-flex;
height: 32px;
padding: 10px 14px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
margin-top: 29px;
}