.a-event{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    margin-bottom: 46px;
    margin-top: 42px;

}
.under-line1{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
}
.name-language{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
}
.time-location{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 20px;
}

.book-details,
.book-names,
.book-type{
display: flex;
justify-content: center;
align-items: baseline;
flex-direction: column;
gap: 0px;
}
.book-details h3,
.book-names h3,
.book-type h3{
    margin-top: 0px;
    margin-bottom: 0px;

}
.book-details h4,
.book-names h4,
.book-type h4{
    margin-top: 0px;
    margin-bottom: 0px;

}
.book-details{
    color:  #FEFEFE;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 184.615% */
}
.book-details h3{
    color:  #FEFEFE;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.book-details h4{
    color:  #FEFEFE;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 184.615% */
}

.book-names{
    color:  #FEFEFE;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 184.615% */
}
.book-names h3{
    color:  #FEFEFE;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 184.615% */
}
.book-names h4{
    color:  #FEFEFE;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 184.615% */
}

.book-type{
    color:  #FEFEFE;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 184.615% */
}
.book-type h3{
    color:  #FEFEFE;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 184.615% */
}
.seperator{
    stroke-width: 1px;
background-color:  #EFEFEF;
width: 1px;
height: 80px;

}
.three-b{
    border-radius: 4px;
border: 1px solid  #FEFEFE;
background-color:  #1F262D;
display: flex;
width: 442px;
width: 100%;
height: 32px;
padding: 0px 14px;
justify-content: center;
align-items: center;
gap: 8px;
color: #FFF;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.three-b:hover{
    color: var(--primary-black);
    background-color: var(--primary-white);
}
.b3{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 9px;
    width: 100%;
    max-width: 442px;
    cursor: pointer;
}
@media (max-width:1030px) {
    .a-event{
        width: 80%;
        flex-direction: column;
        max-width: 552px;
        flex-direction: column;
    }
    .seperator{
        height: 1px;
width: 80px;
    }
    .book-details, .book-names, .book-type {
        flex-shrink: 0;
    }
    .detail-left1{
        gap: 32px !important;
        justify-content: center !important;
    }
}

@media (max-width:550px) {
.detail-left1{
    flex-direction: column !important;
}
.book-details,
.book-type{
    width: 100%;
}
.name-language{
    width: 100%;
    gap: 0px;
    margin-bottom: 8px;
}
.time-location{
    width: 100%;
    gap: 8px;
    align-items: baseline;
}
}