.slider {
    widows: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    max-width: 1200px;
    max-height: 332px;
    margin-top: 40px;
}

.slide {
    width: 100%;
    transition: all ease-out 0.2s;
    border-radius: 10px;
}

.prev_btn {
    position: absolute;
    width: 50px;
    font-size: 20px;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    z-index: 10;
    transition: all ease 0.1s;
}

.prev_btn:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.next_btn {
    position: absolute;
    width: 50px;
    font-size: 20px;
    height: 100%;
    top: 50%;
    right: 0;
    border: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    transform: translateY(-50%);
    z-index: 10;
}

.next_btn:hover {
    background-color: rgba(0, 0, 0, 0.5);
    transition: all ease 0.1s;
}

.landing-page {
    background-color: var(--primary-black);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;

}

.landing-page {
    background-image: url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\1.svg'), url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\3.svg');
    background-repeat: no-repeat;
    background-position: top left, right bottom;
    width: 100%;
}

.no-data {
    background-image: url(C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\rht.png), url(C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\lht.png);
    background-repeat: no-repeat;
    background-position: top right, top left;
    width: 100%;
}

.explore {
    margin-top: 18px;
}

.paragraph {
    width: 100%;
    max-width: 382px;
    color: var(--secondary-white);
    text-align: center;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 16px;
    margin-bottom: 12px;


}

.signin {
    color: var(--hint-text);
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

}

.signin:hover {
    color: var(--primary-white);
}

.checkoutthings {
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 133.333% */
    display: flex;
    width: 100%;
    height: 18px;
    flex-direction: column;
    justify-content: center;
}

.alllinks {
    display: flex;
    gap: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.plink {
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 5.333px;
    border: 0.667px solid #FEFEFE;
    background: #0E1111;
    box-shadow: 0px 0.667px 2.667px 0px rgba(0, 0, 0, 0.25);
    max-width: 130px;
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
}

.plink:hover {
    background: #FEFEFE;
    color: #0E1111;
}

.commingsoon {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-top: 120px;
}

.commingsoon h3 {
    color: var(--text-secondary, #EFEFEF);
    text-align: center;
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    max-width: 622px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
}

.commingsoon p {
    color: #959595;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
}

.email-noti {
    display: inline-flex;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.email-noti input {
    color: #959595;
    font-family: Montserrat;
    font-size: 10.667px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 4px;
    border: 0.667px solid  #FEFEFE;
    background:  #0E1111;
    box-shadow: 0px 0.667px 2.667px 0px rgba(0, 0, 0, 0.25);
    max-width: 243px;
    width: 100%;
    height: 32px;
    flex-shrink: 0;
padding-left: 12.5px
}

.email-noti button {
    color: var(--text-primary, #FEFEFE);
    font-family: "Open Sans";
    font-size: 10.667px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 5.333px;
    border: 0.667px solid var(--container-white, #FEFEFE);
    background: var(--secondary-black, #262626);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    display: flex;
    height: 32px;
    padding: 4.667px 30px;
    justify-content: center;
    align-items: center;
    gap: 5.333px;
}