.custom-accordion {
  text-align: center;
  margin-top: 50px;
}


#faqs{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.accordion-container {

  border-radius: 10px;
  /* Rounded corners for the container */
  padding: 20px;
  /* Add padding inside the container */
  /* padding-top: 90px;
    padding:140px; */
  padding-top: 60px;
  /* Set a minimum width for the accordion container */
  width: 800px;
  /* You can adjust the maximum width as needed */
  margin: 0 auto;
  /* Center the container horizontally */
  height: 100%;
  margin-bottom: 99px;
  display: flex;
  justify-content: center;
}

.accordion-item {
  border-radius: 8px;
  /* Rounded corners for each accordion item */
  margin-bottom: 16px;
  /* Spacing between accordion items */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease;
  /* Smooth background color transition */

}

/* .accordion-item:hover {
  background-color: rgba(255, 255, 255, 1);
} */

.accordion-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 8px;
border: 1px solid  #FEFEFE;
background:  #1F262D;

color: #DCDCDC;

font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 32px;
max-width: 569px;
width: 100%;

}

.accordion-answer {
  padding: 0 16px 16px 16px;
  display: none;
  font-size: 16px;
  font-family: Inter;
}

.active5 .accordion-answer {
  display: block;
  /* Show the answer when the accordion is active */
  background-color: var(--primary-black);
  color: #EFEFEF;
  /* Body Text 2 - 16px Regular */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-top: 22px;
}

.accordian-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  /* Align text to the left */
  padding: 10px 20px;
  /* Add left-right padding */
  margin: 10px 0;
  /* Add top-bottom margin for spacing between items */
  padding-left: 30px;
  padding-right: 30px;
  max-width: 569px;
  width: 100%;
}
.accordion-icon{
  
    display: flex;
    justify-content: center;
    align-items: end;

}

@media (max-width: 850px) {
  .accordion-container {
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 24px;
    border-radius: 8px;
    margin-right: 24px;
    margin-left: 24px;

  }

  .accordian-item {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.active5{
  background-color: var(--primary-black) !important;
}
@media (max-width: 500px) {
  .accordion-title {
    padding: 24px 0px 0px !important;

  }
  .accordion-container {

    border: none;
    margin-left: 0px;

  }
}