@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.general-section {
    padding-top: 60px;
    background-color: var(--primary-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.general-heading {
    color: #FEFEFE;
    font-family: var(--font-montserrat);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;

}

.general-line {
    display: block;
    margin: 0 auto;
    /* Center the line image horizontally */
    padding-bottom: 48px;
}


  .brand-collaborations {
    padding-top: 48px;
    text-align: center;
  }  
  .brand-cont{
    background-color: var(--Dark-blue);
    height: 185px;
    width: 100%;
    margin-bottom: 125px;
    padding-top: 32px;
  }
  .all-brands{
    max-width: 756px;
    display: flex;
    flex-wrap: wrap;
    gap: 29px;
    row-gap: 27px;
    justify-content: center;
    align-items: center;
    margin-bottom: 136px;    
  }