/* NewsletterPopup.css */

.newsletter-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure the popup is above other content */
    backdrop-filter: blur(2px);

  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 460px;
    text-align: center;
    padding-top: 0px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
  }
  
  button {
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .news-btn{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    position: relative;
    right: -129px;
    top: 0px;

  }
  .news-btn button{
    color: transparent;
    background-color: transparent;
    padding: 0px 0px;
  }
  .top-para{
    display: flex;
    justify-content: center;
    align-items: center;
    color:  #959595;
text-align: center;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 123.077% */
  }
.lines{
    max-width: 438px;
    color:  #0E1111;
text-align: center;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 169.231% */
margin-top: 64px;
margin-bottom: 44px;
margin-left: 33px;
margin-right: 29px;
}
  
.enter-main input{
    width: 370px;
height: 40px;
border-radius: 8px;
border: 2px solid #000;
}

.subscribe button{
    width: 260px;
height: 50px;
border-radius: 8px;
background-color:  #0E1111;
}
.content1{
    width: 80%;
    border-radius: 8px;
    background-color: #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 182px;
}
.check{
  margin-top: 24px !important;
}


@media (max-width:500px) {
  .newsletter-popup{
    align-items: end;
  }
  .popup-content{
    padding: 5px;
  }
  .enter-main{
    width: 100%;
  }
  .enter-main input{
    width: 90%;

  }
  .subscribe{
    width: 100%;
  }
  .subscribe button{
    width: 60%;
  }
  .news-btn{
    position: relative;
    right: -85px;
    top: 0px;

  }
}