.slide {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
  }
  
  .slideWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .prevArrow {
    position: absolute;
    top: 40%;
    left: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
  }
  
  .nextArrow {
    position: absolute;
    top: 40%;
    right: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
  }

  .prevArrow,
  .nextArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
}
  
  .prevArrow:hover,
  .nextArrow:hover {
    transform: translateY(-50%) scale(1.5);
    transition: transform 0.3s ease-in-out;
    color: var(--primary-white);    
  }
  