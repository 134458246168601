.time-select {
    width: 100%;
    display: flex;
    font-size: 3rem;
}
.selected-date{
    width: 100%;
    display: flex;
    padding-left: 14px;
    padding-bottom: 13px;
}