.general-section {
    padding-top: 60px;
    background-color: var(--primary-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.general-heading {
    color: #FEFEFE;
    font-family: var(--font-montserrat);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;

}

.general-line {
    display: block;
    margin: 0 auto;
    /* Center the line image horizontally */
    padding-bottom: 48px;
}
.box1 {
    /* border: 1px solid black; */
    width: 100%;
    margin-left: 0;
  }
  

  
  .arr {
    align-items: center;
    width: 40px;
height: 40px;
  }
  
  /* .App > div :nth-child(1) {
    width: 60%;
  }
  
  .App > div :nth-child(2) {
    width: 35%;
  } */
  