.general-section {
    padding-top: 60px;
    background-color: var(--primary-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.general-heading {
    color: #FEFEFE;
    font-family: var(--font-montserrat);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;

}

.general-line {
    display: block;
    margin: 0 auto;
    /* Center the line image horizontally */
    padding-bottom: 48px;
}

.advertise-heading {
    max-width: 736px;
    color: #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
}

.advertise {
    color: #FEFEFE;
    text-align: center;
    max-width: 795px;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
    margin-bottom: 42px;
    padding-left: 8px;
    padding-right: 8px;
}

.whyadvertise {
    max-width: 1120px;
    min-height: 366px;
    background-color: var(--Dark-blue);
    margin-bottom: 36px;
    margin-left: 80px;
    margin-right: 80px;


}

.why-heading {
    color: #FEFEFE;
    text-align: center;

    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    /* 131.25% */
}
.whyadvertise p{
    color: #FFF;

font-family: Open Sans;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px;
padding-left: 137px;
padding-right: 137px;
}
.whyadvertise p b{
font-weight: 700;
}


.whyadvertise2 {
    max-width: 1120px;
    min-height: 366px;
    background-color: var(--Dark-blue);
    margin-bottom: 36px;
    margin-left: 80px;
    margin-right: 80px;


}

.why-heading2 {
    color: #FEFEFE;
    text-align: center;

    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    /* 131.25% */
}
.whyadvertise2 p{
    color: #FFF;

font-family: Open Sans;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px;
padding-left: 137px;
padding-right: 137px;
}
.whyadvertise2 p b{
font-weight: 700;
}
.getintouch button{
    display: flex;
    height: 48px;
    padding: 7px 45px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid  #FEFEFE;
    background-color: var(--primary-black);

}
.getintouch button:hover{
    background-color: var(--primary-white);
    color: var(--primary-black);
}
.bottompara{
    color: #FFF;
    text-align: center;

    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    max-width: 825px;
    margin-bottom: 107px;
    padding-left: 8px;
    padding-right: 8px;
}
.getintouch button:hover{
    background-color: var(--primary-white);
    color: var(--primary-black);
}

@media (max-width:700px) {
    .whyadvertise{
        margin-left: 0px;
        margin-right: 0px;
    }
    .whyadvertise2{
        margin-left: 0px;
        margin-right: 0px;
    }
    .whyadvertise p{
        color: #FFF;
    
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding-left: 37px;
    padding-right: 37px;
    }
    .whyadvertise2 p{
        color: #FFF;
    
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding-left: 37px;
    padding-right: 37px;
    }
}

/* CSS */
.popup-overlay1 {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 9999;
    justify-content: center;
    align-items: center;
    display: none;

  }
  
 
  
  .popup1 {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .popup-overlay1.open {
    display: flex;
  }
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;

  }
  .popup1 p{
    color: var(--hint-text, #959595);
text-align: center;

font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 16px; /* 123.077% */
margin-top: 72px;
  }
  .popup1 h2{
    max-width: 311px;
    color:  #0E1111;
text-align: center;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 0px;
  }
  .req-a-call{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 184px;
height: 50px;
flex-shrink: 0;
border-radius: 8px;
background-color:  #0E1111;
color:  #FEFEFE;

font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 157.143% */
margin-top: 12px;
margin-bottom: 100px;
  }
  
@media (max-width:850px) {
    .getintouch button{
        background-color: var(--primary-white);
        color: var(--primary-black);
    }
    .advertise{
        width: 90%;
    }
}