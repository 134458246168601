.top {
  display: flex;
  flex-direction: row;
  height: 80px;
  background-color: var(--primary-black);
  align-items: center;


}

.nav-logo {
  width: 50.925px;
  height: 50px;

}

.logo-div {
  /* margin-left: 86px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 44px;
  cursor: pointer;


}

.brand-name {
  color: var(--primary-white, #FEFEFE);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;

}

.search-bar,
.cityname,
.cityname1,
.nav-button,
.menu,
.signin-btn {
  display: flex;
  justify-content: center;
  align-self: center;
}

.signin-btn {
  display: flex;
  width: 124px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #FEFEFE;
  background-color: #1F262D;
  color: #FEFEFE;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 5.333px;
  border-radius: 5.333px;
  border: 0.667px solid #FEFEFE;
  background-color: #262626;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  color: #FEFEFE;
  font-family: Open Sans;
  font-size: 10.667px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  
}
.search-button{
height: 32px;
padding: 16.667px 31px;;

}
.search-bar {
  max-width: 276.333px;
  height: 32px;
  width: 100%;
}


.search input {
  width: 100%;
height: 32px;
  flex-shrink: 0;
  border-radius: 5.333px;
  border: 0.667px solid #FEFEFE;
  background-color: #0E1111;
  box-shadow: 0px 0.66667px 2.66667px 0px rgba(0, 0, 0, 0.25);
  color: whitesmoke;
  padding-left: 16px;
  width: 100%;
}

.menu button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  /* padding: 0px 20px; */
  padding-top: 14px;
}

.cityname {
  position: relative;
  display: inline-block;
  max-width: 130px;
width: 100%;
  height: 32px;
}
.cityname1 {
  position: relative;
  display: inline-block;
  max-width: 130px;
width: 100%;
  height: 32px;
}

.cityname input {
  max-width: 130px;
  width: 100%;
    height: 31px;
  border: 1px solid var(--primary-white);
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  background-color: var(--system-colors-black-dark);
  color:  #FEFEFE;
  padding-left: 8px;

}
.cityname1 input {
  max-width: 130px;
  width: 100%;
    height: 31px;
  border: 1px solid var(--primary-white);
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  background-color: var(--system-colors-black-dark);
  color:  #FEFEFE;
  padding-left: 8px;

}

.nav-item {
  list-style: none;
  color: var(--text-primary, #FEFEFE);
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-y: auto;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background: var(--Dark-blue);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

}

.nav-item div {
  height: 61px;
  color: #FFF;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 0.5px solid #FEFEFE;
  border-bottom: 0.5px solid#FEFEFE;
  text-align: center;
  display: flex;
  cursor: pointer;
  /* position: relative; */
}



.nav-item div:hover {
  background-color:var(--primary-black);
  color: var(--primary-white);
}
.notsign-nav:hover{
  background-color:var(--primary-black);
  color: var(--primary-white);
}


.city-child2 {
  position: absolute;
  top: 12.94px;
  right: 5.44px;
  width: 18.12px;
  height: 10.56px;

}

/* Right-side navbar */
.right-navbar {
  position: fixed;
  top: 0;
  right: -325px;
  /* Initially hidden off-screen */
  width: 325px;
  height: 100vh;
  background-color: var(--Dark-blue);
  /* Blue background color */
  transition: right 0.3s ease-in-out;
  padding-top: 8px;
  /* Space for the top section */
  z-index: 11;
}

.right-navbar ul {
  list-style: none;
  padding: 0;
}

.right-navbar ul li {
  padding: 10px 20px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.right-navbar ul li:hover {
color: red;
}

.searches {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-grow: 0.91;
}
.pr{
  padding-right: 40px;
}

/* Open the right-side navbar */
.right-navbar.open {
  right: 0;
}

.right {
  display: flex;
  justify-content: space-around;
  padding: 19px;
  padding-right: 13px;
  padding-left: 0px;

}

.signin-btn {
  display: flex;
  width: 124px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #FEFEFE;
  background-color: #1F262D;
  color: #FEFEFE;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 44px;
}



/* middle */
.mid {
  background-color: var(--primary-black);
  justify-content: center;
  align-self: center;
  height: 91px;
  display: none;
}

.search-bar,
.cityname,
.nav-button {
  margin-left: 8px;
  margin-right: 8px;
}


/* bottom */
.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--rare-black, #1F262D);
  min-height: 32px;
}
.bottom2 {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: var(--rare-black, #1F262D);
  min-height: 32px;
}
.items{
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  gap: 16px;
  cursor: pointer;

}

.items1,
.items2 {
  color: var(--texts) !important;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  gap: 32px;
  overflow: overlay;
  cursor: pointer; 
}
.items-line h3:hover,
.items1 h3:hover,
.items2 h3:hover{
  color: var(--primary-black);
  background-color: var(--primary-white);
  border-radius: 4px;
}

.items1 h3,
.items2 h3
{
  color:  #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 8px;
  margin-bottom: 8px;

}


.feilds{
  display: flex;
}

.nav-item div img{
  padding-right: 8px;
}
.nav-ul li {
  padding: 10px;
  cursor: pointer;
  color:  #FEFEFE;
  border: 0.411px solid #FEFEFE;

}

.nav-ul li:hover {
  background: var(--container-blue, #1F262D);
  
  }

  .nav-button button:hover{
    background-color: var(--primary-white);     
    color: var(--primary-black);

  }
  .signin-btn:hover,
  .signinbtn button:hover{
    background-color: var(--primary-white);     
    color: var(--primary-black);
   
  }
  .items-line{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-white);
    gap: 40px;
    cursor: pointer;

  }
  .items-line h3{
    margin-top: 0px;
    margin-bottom: 0px;
    color:  #FFF;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
padding-left: 4px;
padding-right: 4px;
flex-shrink: 0;

  }

.search:hover{
  box-shadow: none;
}
.profile-img{
  width: 121px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 16px;
border: 1px solid  #FEFEFE;
display: flex;
color:  #FEFEFE;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 36px; /* 225% */
gap: 12px;
align-items: center;
cursor: pointer;

}
.profile-img2{
  margin-right: 52px;

}
.prifile-holder{
  height: 32px;
  width: 32px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.profiles{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notsign-nav {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 38, 45, 0.50);
backdrop-filter: blur(2px);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

}
.notsign-nav:hover{

}
.profile-img h3{
  color:  #FEFEFE;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 36px; /* 225% */
text-transform: capitalize;

}

.active3{
  background-color: var(--primary-white);
  color: var(--primary-black) !important;
  border-radius: 4px;
}
.menu{
  flex-grow: 0.09;
}
.removemobile{
  display: flex;
  
}
@media (max-width:1058px) {
  .top{
    background-color: var(--Dark-blue);
  }
  .profile-img2{
    position: absolute;
    right: 8px;
    margin-right: 0;
  }
  /* .search-bar, .cityname, .nav-button {
    margin-left: 0px;
    margin-right: 0px;
} */
  .searches{
    display: none;
  }
  .cityname{
    display: none;
  }
  .mid{
    display: flex;
  }
  .feilds {
    justify-content: center;
    align-items: center;
    width: 38%;
}
  .menu{
      position: absolute;
      right: 128px;
  
  }
  .signin-btn{
    position: absolute;
    right: 10px;
    margin-right: 0;
  }
.items{
  display: flex;
  flex-direction: column;
  gap: 0px;

}
.items-line{
  display: none;
}
.cityname1{

}
@media (max-width:750px) {
  .feilds{
   width: 51%;
  }
}

}
@media (max-width:500px) {
.signin-btn-remove{
  display: none;
}
.bottom2{
  display: flex;
}
.signin-btn{
  margin-right: 28px;
}
.feilds{
  width: 90%;
  

}
.profile-img2{
  display: none;
}
.profile-img{
  
}
.menu {
  position: absolute;
  right: 20px;
}
.mid{
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  align-items: center;
}

.nav-logo {
  width: 41.925px;
  height: 41px;
}
.brand-name {
  font-size: 20px;
 
}
.items{
  display: none;
  flex-direction: row;
  gap: 32px;
}
.items::-webkit-scrollbar {
  display: none;
}
.items-line h3:first-child{
padding-left: 22px;
}
.items-line{
  display: flex;
  overflow: scroll;
  justify-content: unset;
}
.cityname1{
  max-width: 98%;
}
.cityname1 input{
max-width: 90%;
}
.nav-button,
.search{
  flex-grow: 1;
}
.city-child2 {
  position: absolute;
  top: 12.94px;
  right: 25.44px;
  width: 18.12px;
  height: 10.56px;
}
.search-bar{
  max-width: 250px;
}
} 

@media (max-width:427px) {
  .logo-div{
    padding-left: 4px;
  }
  .search {
    width: 100%;
}
.search-bar{
  max-width: 180.333px;

}
.cityname1 input {
  max-width: 87%;
}
.search-bar, .cityname, .nav-button {
  margin-left: 8px;
  margin-right: 8px;
}
.cityname1{
  max-width: 100%;
}
}

