.withdraw{
    width: 90%;
height: 60px;
flex-shrink: 0;
border: 1px solid  #FEFEFE;
background-color:  #262626;
margin-bottom: 37px;
display: flex;
justify-content: space-between;
align-items: center;
}
.balance{
    color: #FFF;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 110% */
padding-left: 30px;
}
.withdraw-btn{
    border-radius: 8px;
background-color:  #FEFEFE;
display: inline-flex;
padding: 9px 28px;
justify-content: center;
align-items: center;
gap: 8px;
margin-right: 23px;
cursor: pointer;
}
.trans1{
    color:  #FEFEFE;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px;
}
.ids1{
    justify-content: center;
}
.bank-detail{
    color: #FFF;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 110% */
width: 90%;
display: flex;
}
.account-detail{
    border: 1px solid #FEFEFE;
background-color:  #262626;
width: 90%;
min-height: 164px;
position: relative;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 16px;
display: flex;
flex-direction: column;
}
.edit{
    position: absolute;
    top: 14px;
    right: 13px;
}
.add-btn{
    color:  #0E1111;
font-family: Montserrat;
font-size: 8.667px;
font-style: normal;
font-weight: 500;
line-height: 12.133px; /* 140% */
border-radius: 8px;
background-color:  #FEFEFE;
display: inline-flex;
padding: 9px 28px;
justify-content: center;
align-items: center;
gap: 8px;
margin-top: 16px;
margin-bottom: 39px;
cursor: pointer;
}
.holder-detail{
width: 90%;
}
.holder-detail h3{
    color: #FFF;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */
margin-top: 0px;
margin-bottom: 0px;
display: flex;
width: 100%;
}
.holder-detail1{
display: flex;
flex-direction: column;
gap: 16px;
}
.holder-detail1 input{
    max-width: 586px;
    width: 100%;
height: 48px;
border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color:  #262626;
color:  #959595;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22.4px; /* 140% */
letter-spacing: 0.08px;
padding-left: 16px;
}
.edit-mode1{
    display: none;
}
.withdraw-amount-screen{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;

}
.withdraw-amount-screen input{
    max-width: 586px;
    width: 100%;
height: 48px;
border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color:  #262626;
color:  #959595;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22.4px; /* 140% */
letter-spacing: 0.08px;
padding-left: 16px;
}
.with-amount{
    color:  #959595;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: 12px; /* 92.308% */
letter-spacing: 0.195px;
width: 80%;
display: flex;
margin-top: 8px;
}
.otp-screen{

}
.otp-screen h2{
    color:  #FEFEFE;
text-align: center;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 22px; /* 157.143% */
margin-top: 0px;
margin-bottom: 0px;
}
.otp-screen p{
    color:  #FEFEFE;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    margin-top: 0px;
margin-bottom: 35px;
}
.line2{
    color:  #FEFEFE;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */
margin-top: 32px;
margin-bottom: 30px;
}
.thank-you-screen{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.thank-you-screen p{
    max-width: 525px;
    color:  #FEFEFE;
text-align: center;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
margin-top: 0px;
margin-bottom: 0px;
}
@media (max-width:1057px) {
    .balance{
font-size: 12px;
font-weight: 600;
line-height: 22px; /* 183.333% */
    }
    .data{
        flex-direction: column;
        min-height: 115px;
    }
    .amounts{
        align-items: flex-end;
    }
}

@media (max-width:756px) {
.withdraw{
    flex-direction: column;
    min-height: 60px;
    padding-top: 32px;
    padding-bottom: 32px;
    gap: 8px;
}
.balance{
    padding-left: 0px;
}
.data{
    min-height: 165px;
    width: 65%;

}
.trans1{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.period{
    width: 72%;
}
}

@media (max-width:700px) {
.left-div{
    max-width: 650px;
    width: 90%;
}

.withdraw{
    height: auto;
}
}

@media (max-width:350px) {
    .update-password,
.email-notification,
.notification-setting,
.Transaction,
.logout {

    max-width: 287px;
    width: 90%;

    /* 150% */
}
.mobile-view{
    padding: 0px;
}
.data{
    gap: 8px;
    min-height: 140px;
}
.ids1{
    align-items: center;
}
.amounts{
    align-items: center;
}
}