.settings {
    display: flex;
    gap: 32px;
    margin-bottom: 80px;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
}

.left-div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.right-div {
    width: 49%;
    min-height: 390px;
    flex-shrink: 0;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.update-password,
.email-notification,
.notification-setting,
.Transaction,
.logout {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 287px;
    height: 64px;
    flex-shrink: 0;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    gap: 16px;
   

    /* 150% */
}

.update-password-comp {
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    text-transform: uppercase;

}

.update-password-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}
.password-input{
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 284px;

}
.password-input input {
    display: flex;
    max-width: 284px;
    height: 42px;
    width: 100%;
    padding-left: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    color: var(--hint-text, #959595);

/* Body Text 2 - 16px Medium */
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}
.pass-eye{
  right: 10px;
position: absolute;
top: 15px;

}
.update{
    display: inline-flex;
height: 32px;
padding: 7px 45px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid  #FEFEFE;
color:  #FEFEFE;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 12px;
margin-bottom: 23px;
cursor: pointer;
max-width: 146px;
background-color: transparent;

}
.update:hover{
  background-color: var(--primary-black);
}

.email-noti{
    color:  #FFF;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
text-transform: uppercase;
}
.order-noti,
.receive-noti{
color:  #FEFEFE;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 110% */
}
/* EmailNotification.css */

.email-noti {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .toggle-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
        gap: 24px;

  }
  
  label {
    flex: 1;
  }
  
  .slider1 {
    width: 48px;
    height: 26px;
    background-color: #ccc;
    border-radius: 15px;
    display: flex;
    cursor: pointer;
  }
  
  .slider1.actives {
    background-color: #4caf50 !important; 
  }
  
  .slider-button {
    width: 26px;
    height: 26px;
    background-color: white;
    border-radius: 50%;
    transition: 0.2s;
  }
  
    .mobile-view {
      border: 1px solid var(--primary-white);
      padding: 10px;
      background-color: var(--Dark-blue);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

  }
  
  .slider-button.actives {
   /* background-color:  #FFC296; */
   background-color: white !important;
    transform: translateX(20px); /* Move the button to the right when actives */
  }
  .selected{
    background-color: var(--Dark-blue);
    /* color: var(--primary-black); */
  }
  .noti-main{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
  }
  .noti-setting{
    color:  #FFF;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
text-transform: uppercase;
width: 100%;

  }
  .noti-head{
    color:  #FEFEFE;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 110% */
margin-top: 50px;
width: 100%;


  }
.whiteline{
  margin-bottom: 32px;
}
.upcoming{
  color:  #FEFEFE;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 110% */
padding-top: 12px;
padding-bottom: 12px;
display: flex;
gap: 32px;
}

.transaction-history {
  width: 90%;
  border: 1px solid #FEFEFE;
  background-color: #262626;
  min-height: 200px;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 56px;
  height: 325px;
  overflow-y: scroll;
}
.data{
  min-height: 72px;
  width: 90%;
  border-bottom: 1px solid #959595;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 24px;



}
.ids{
  display: flex;
  flex-direction: column;
  align-items: baseline;

}
.amounts{
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: baseline;

}
.trans{
  color:  #FEFEFE;
font-family: Open Sans;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 22px;
}
.period{
  color:  #FEFEFE;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.des{
  color:  #FEFEFE;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  max-width: 250px;
  display: flex;
  text-align: initial;

}
.amount{
  color:  #FEFEFE;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 169.231% */
}
.status{
  color:  #FEFEFE;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 169.231% */
}
.views{
  display: inline-flex;
padding: 9px 28px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background-color:  #FEFEFE;
color:  #0E1111;
font-family: Montserrat;
font-size: 8.667px;
font-style: normal;
font-weight: 500;
line-height: 12.133px; /* 140% */
margin-top: 32px;
margin-bottom: 32px;
}
.hist{
  color:  #FFF;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
text-transform: uppercase;
margin-top: 28px;
margin-bottom: 53px;
}
.pass-eye{

}

.no-transaction{
  color: #FEFEFE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}

@media (max-width:700px) {
    .right-div {
        display: none;
    }
    .data{
      flex-direction: column;
      gap: 29px;
    }
}
@media (max-width:800px) {
    .update{
      background-color: var(--primary-white);
     color: var(--primary-black);
    }
}
