.general-section {
    padding-top: 60px;
    background-color: var(--primary-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.general-heading {
    color: #FEFEFE;
    font-family: var(--font-montserrat);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;

}

.general-line {
    display: block;
    margin: 0 auto;
    /* Center the line image horizontally */
    padding-bottom: 48px;
}

.contact-heading {
    color: #FEFEFE;
    text-align: center;
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    line-height: 42px;
    max-width: 909px;
}

.formcontainer {
    border-radius: 16px;
    border: 1px solid #FEFEFE;
    background: var(--Dark-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 124px;
    padding-left: 90px;
    padding-right: 90px;
    margin-top: 80px;
}
.contact-form{

}
.bothnames{
    display: flex;
    width: 100%;
    gap: 12px;
}
.names{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 58px;
    width: 100%;
position: relative;
}

.names input {
    width: 240px;
    padding: 4px 8.5px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #FEFEFE;
    background: #262626;
    min-height: 40px;
    padding: 8px 0px;
    padding-left: 10px;
    color: var(--primary-white);
    font-family: Montserrat;
    font-size: 16px;
font-weight: 500;
color: rgba(149, 149, 149, 1);
}
.emailaddress{
    margin-top: 9px;
    margin-bottom: 9px;
    width: 100%;
}

.emailaddress input {
    display: flex;
    width: 100%;
    padding: 8px 0px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #FEFEFE;
    background: #262626;
    height: 40px;
    color: var(--primary-white);
padding-left: 10px;
font-family: Montserrat;
font-size: 16px;
font-weight: 500;
color: rgba(149, 149, 149, 1);
}
.messagehere{
    width: 100%;
}
.messagehere textarea {
    display: flex;
    width: 100%;
    height: 122px;
    padding: 8px 0px;
    align-items: flex-start;
    justify-content: baseline;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #FEFEFE;
    background: #262626;
    height: 122px;
    resize: none;
padding-left: 10px;
font-family: Montserrat;
font-size: 16px;
font-weight: 500;
color: rgba(149, 149, 149, 1);

}
.submit-btn button{
    color: var(--primary-white);
    display: inline-flex;
height: 32px;
padding: 7px 45px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color: var(--Dark-blue);
}
.submit-btn button:hover{
    color: var(--primary-black);
    background-color: var(--primary-white);
}
.submit-btn{
    margin-top: 16px;
    margin-bottom: 28px;
}
.errorMessage2{
    position: relative;
    top: -1px;
    left: 10px;
    flex-shrink: 0;
    color: #ca0000;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 4px;
    display: flex;

}

@media (max-width:750px) {
    .formcontainer{
        padding-left: 40px;
        padding-right: 40px;
    }
    .bothnames{
        flex-direction: column;
        margin-top: 20px;
    }
    .names{
        flex-direction: column;
        width: 100%;
        margin-top: 0px;
    }
    .names input{
        padding: 0px 0px;
        padding-left: 10px;


       
    }
    .emailaddress{

    }
    .emailaddress input{
        padding: 0px 0px;
        padding-left: 5px;


    }
    .messagehere{

    }
    .messagehere input{
        padding: 0px 0px;
        padding-left: 5px;


    }
    
}
@media (max-width:900px) {
    .contact-heading{
        width:85%
    }
}
@media (max-width:450px) {
    .contact-heading{
        font-size: 18px;
    }
}
@media (max-width:350px) {
    .formcontainer{
        padding-left: 10px;
        padding-right: 14px;
        width: 90%;

    }
    .emailaddress input {
        padding: 0px 0px;
        padding-left: 5px;
    }
    .messagehere input{
        padding: 0px 0px;
        padding-left: 5px;


    }
    
}