.grab{
height: 403px;
flex-shrink: 0;
width: 100%;
}

.backgroundimage{
    /* background-image: url('C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\Adv.png'); */
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  border-top: 1px solid  #FEFEFE;
border-bottom: 1px solid  #FEFEFE;
margin-top: 64px;
position: relative;
max-height: 403px;
height: 100%;
overflow: hidden;
display: flex;
justify-content: center;
align-items: center;
}
.grab-img{
    position: absolute;
    filter: brightness(0.4);

}
.textarea{
z-index: 1;
}
.texts1{
    color:  #FFF;
text-align: center;
font-family: Montserrat;
font-size: 50.357px;
font-style: normal;
font-weight: 500;
line-height: 70.5px;
margin-bottom: 8px;
}
.texts2{
    color:  #FFF;
text-align: center;
font-family: Montserrat;
font-size: 50.357px;
font-style: normal;
font-weight: 500;
line-height: 70.5px;
margin-top: 8px;
margin-bottom: 20px;
}
.booknow{
    display: inline-flex;
height: 48px;
padding: 7px 45px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid  #FEFEFE;
color:  #FEFEFE;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
background-color: transparent;
margin-bottom: 97px;
}
.booknow:hover{
    background-color: var(--primary-white);
    color: var(--primary-black);
}

@media (max-width:640px) {
    .texts1,
    .texts2{
        font-size: 32px;
    }
    .texts1{
        margin-bottom: 0px;
    }
    .texts2{
        margin-top: 0px;
    }
    
}