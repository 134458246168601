.App {
    text-align: center;
}

.carousel-container {
    width: 80%;
    /* overflow: hidden; */
    position: relative;
    margin: 0 auto;
    margin-bottom: 48px;
}

.carousel {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
}

.carousel-card {
    flex: 0 0 25%;
    /* Show 4 cards at a time */
    background-color: #fff;
    transform: translateX(100%);
    transition: transform 0.5s, opacity 0.5s;


}

.carousel-card.active {
    transform: translateX(0);
    opacity: 1;
}

.image {
    flex: 1;
    overflow: hidden;
    margin: 0;
    padding: 0;
    min-width: 252px;
    min-height: 247.08px;

}

.image img {
    width: 100%;
    max-height: 252px;
    object-fit: cover;
    border-radius: 16px 16px 0px 0px;
    display: block;
    /* Make sure the image is treated as a block element */
    width: 100%;
    /* Ensure the image takes the full width of its parent div */
    height: auto;

}

.details {
    text-align: start;
    background-color: var(--primary-black);
}

.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
}

.prev-button:hover,
.next-button:hover {
    transform: translateY(-50%) scale(1.5);
    transition: transform 0.3s ease-in-out;
    color: var(--primary-white);
}

.prev-button {
    left: -80px;
}

.next-button {
    right: -80px;
}

.carousel {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    justify-content: center;
    /* Adjust margin to create spacing between cards */
    transition: transform 0.3s ease-in-out;
    position: relative;
    transform-origin: bottom;
    /* Set the transform origin to the bottom */
}

.carousel-card {
    flex: 0 0 calc(25% - 16px);
    /* Show 4 cards at a time with 16px gap */
    background-color: #fff;
    /* max-height: 400px; */
    border-radius: 16px 16px 0px 0px;
    margin: 0 8px;
    max-width: 252px;
    /* Create spacing between cards */
    transform: translateX(0);
    /* Set the initial position to 0 */
    transition: transform 0.3s ease-in-out;
    position: relative;
    transform-origin: bottom;
    /* Set the transform origin to the bottom */
}

.tittle {
    color: var(--primary-white);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    height: 32px;
    background-color: var(--Dark-blue);
    padding-left: 8px;
}

.date,
.location {
    color: #EFEFEF;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.price {
    display: inline-block;
    height: 24px;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 150px;
    background: #262626;
    color: var(--hint-text, #959595);
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 8px;
    margin-left: 8px;
}

.icon {
    padding-left: 8px;
    padding-right: 8px;
}

.icon-text {
    padding-top: 8px;
}

.available {
    display: none;

}

.carousel-card {
    position: relative;
    /* Relative positioning for pseudo-elements */
    /* Other styles for your carousel card */

    /* Define the initial box shadow (no shadow) */
    box-shadow: none;

    /* Add a smooth transition for the box-shadow property */
    transition: box-shadow 0.3s ease-in-out;
}





.carousel-card:hover {
    /* transform: scaleY(1.02);  */
    transition: translate 0.3s ease-in-out;
    height: calc(110% + 10px);
    translate: 0px -10px 0px;
    box-shadow: 0px -70px 150px 0px #404040;
    height: 110%;



    .available {
        display: block;
        display: inline-flex;
        padding: 2px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 150px;
        background-color: var(--primary-white);
        position: absolute;
        right: 7px;
        top: 7px;

        color: #0E1111;

        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
    }

    .price {
        background-color: var(--primary-white);
        color: var(--primary-black);
    }
}

.availability-status-red {
    display: inline-flex;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 150px;
    background-color: #CA0B4A;
    position: absolute;
    right: 7px;
    top: 7px;
    color: #FEFEFE;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.availability-status-yellow {
    display: inline-flex;
    padding: 2px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 150px;
    background: #FD5;
    position: absolute;
    right: 7px;
    top: 7px;

    color: #0E1111;

    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 169.231% */
}

.carousel-card {

    background: linear-gradient(0deg, var(--primary-black) 0%, #eeeeee 100%);
    border-radius: 16px;
    padding: 1px;
    /* box-sizing: border-box; */
}


/* .carousel-card{
    background-image: url("C:\Users\91880\OneDrive\Desktop\youthevent\youthevent\src\media\blur.svg");
    background-repeat: no-repeat;
  background-position: top left;
  width: 100%;
} */



.carousel-card {
    flex: 0 0 calc(25% - 16px);
    /* Display 4 cards */
    margin: 0 8px;
    /* Add spacing between cards */
    height: 386px;

}

/* Media query for screens with a maximum width of 1100px */
@media (max-width: 1100px) {
    .carousel-card {
        flex: 0 0 calc(50% - 16px);
        /* Display 2 cards */
    }
}

/* Media query for screens with a maximum width of 600px */
@media (max-width: 600px) {
    .carousel-card {
        flex: 0 0 calc(100% - 16px);
        /* Display 1 card and center it */
    }
}

@media (max-width: 500px) {
    button {
        padding: 10px 0px;
    }

    .prev-button {
        left: -40px;
    }

    .next-button {
        right: -30px;
    }
}

@media (max-width: 1435px) {

    .prev-button {
        padding: 0px 0px;
        left: -18%;

    }

    .next-button {
        padding: 0px 0px;
        right: -18%;


    }
}

@media (max-width: 1170px) {

    .prev-button {
        left: 0%;

    }

    .next-button {
        right: 0%;


    }
}
@media (max-width: 862px) {

    .prev-button {
        left: -20%;

    }

    .next-button {
        right: -20%;


    }
}
@media (max-width: 600px) {

    .prev-button {
        left: -15%;

    }

    .next-button {
        right: -15%;


    }
}
@media (max-width: 363px) {

    .prev-button, .next-button {
        position: relative;
        transform: translateY(0%);
        background: transparent;
        border: none;
        cursor: pointer;
        font-size: 24px;
        padding-top: 12px;
    }

    .prev-button:hover, .next-button:hover {
        transform: translateY(0%) scale(1.5);
        transition: transform 0.3s ease-in-out;
        color: var(--primary-white);
    }
}