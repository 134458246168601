.event-details {
    background-color: var(--primary-black);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card-detail {
    width: 1120px;
    height: 214px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    margin-top: 55px;
    margin-bottom: 11px;
    display: flex;
    justify-content: space-around;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.thumb {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.2);

}

.event-typo {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    margin-bottom: 22px;
    padding-right: 172px;
    z-index: 1;
}

.ticket-left {}

.time-date {
    display: flex;
    gap: 8px;
    padding-left: 8px;

}

.event-category,
.event-time,
.event-dates {
    display: flex;
    height: 24px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #FEFEFE;
    color: #1F262D;

    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 200% */
}

.event-tittle {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 87.5% */
    margin-bottom: 18px;
    margin-top: 22px;

}

.event-language {
    color: #EFEFEF;

    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 133.333% */
}

.ticket-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    z-index: 1;

}

.insight-sub {
    color: var(--text-secondary, #EFEFEF);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    max-width: 81px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
}

.left {
    width: 219px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: #FEFEFE;
    color: #000;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grab-your {
    display: inline-flex;
    height: 48px;
    padding: 0px 45px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.grab-your:hover {
    background-color: var(--primary-white);
    color: var(--primary-black);
}

.event-location {
    width: 1119px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    margin-bottom: 71px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.addres {
    color: #EFEFEF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    margin-left: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.on-map {
    display: flex;
    height: 32px;
    padding: 1.667px 30px;
    justify-content: center;
    align-items: center;
    gap: 5.333px;
    border-radius: 5.333px;
    border: 0.667px solid #FEFEFE;
    background-color: #FEFEFE;
    color: #0E1111;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin-right: 32px;
}

.about-insight {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.about-event {
    width: 564px;
    height: 466px;
    flex-shrink: 0;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #FEFEFE;
    display: flex;
    justify-content: center;

}

.insight {
    width: 526px;
    height: 466px;
    flex-shrink: 0;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid #FEFEFE;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.term-gallery {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 60px;
    gap: 32px;


}

.term {

    width: 50%;
    max-width: 578px;
    display: flex;
    flex-direction: column;


}

.terms-condition {
    color: #FEFEFE;
    font-family: var(--font-montserrat);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    /* 131.25% */
    margin-bottom: 48px;
    display: flex;
    padding-left: 24px;


}

.gallery-share {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.gallery {
    width: 50%;
    width: 522px;
    height: 498px;
    flex-shrink: 0;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

}

.gallery-head {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    /* 131.25% */
    margin-top: 32px;

}

.event-about {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    align-items: center;

}

.event-about h2 {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    /* 131.25% */
    display: flex;
    width: 95%;


}

.event-about p {
    color: #EFEFEF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    text-align: left;

}

.ticket-checkout-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    overflow: scroll;

}

.slider2 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
}

.right2 {
    position: absolute;
    top: 5%;
    right: 25px;
    z-index: 100;
    cursor: pointer
}

.left2 {
    position: absolute;
    top: 5%;
    z-index: 100;
    left: 25px;
    cursor: pointer;

}

.slide2 {
    opacity: 0;
}

.addres img {
    margin-right: 8px;
}

.active2 {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
    max-width: 445px;
    width: 100%;
    height: 288px;
    flex-shrink: 0;
}

.share {
    height: 96px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    display: flex;

}

.shar {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    /* 175% */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    position: relative;
    left: 13%;
    top: -7px;
}

.shares {
    display: flex;
    gap: 8px;
    align-items: flex-end;
    position: relative;
    top: 25%;
    left: -7%;
}

.share-option {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    border: 1px solid #FFF;
    background-color: #262626;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share-option:hover {
    background-color: #000000;
    cursor: pointer;
}

.insight-name {
    color: #FEFEFE;

    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;

}

.insight-desc {
    color: #EFEFEF;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 184.615% */
    display: flex;
    justify-content: flex-start;
    text-align: initial;
    margin-top: 0px;
    margin-bottom: 0px;
}

.availability-red {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #CA0B4A;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.availability-yellow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: #FD5;
    color: #0E1111;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.large-font {
    font-size: 24px;
}

.email-mobile-content {
    max-width: 680px;
    width: 100%;
    height: 462px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: none;

}
.proceed2{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.news-tn2 {
    right: 18px;
    top: 18px;
    position: absolute;
}

.enter-your {
    border-radius: 8px;
    background: #EFEFEF;
    max-width: 528px;
    width: 90%;
    height: 65px;
    flex-shrink: 0;
    color: #0E1111;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 21px;
    /* 210% */
}

.mains2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;

}

.mains2 input {
    border-radius: 8px;
    border: 1px solid #1F262D;
    background: #EFEFEF;
    max-width: 565px;
    width: 90%;
    height: 50px;
    flex-shrink: 0;
    color: #1F262D;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    padding: 0;
    padding-left: 36px;
}

.proceed2 button {
    margin-top: 36px;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #FEFEFE;
    background: #1F262D;
    display: flex;
    width: 100px;
    height: 48px;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}
.shareswap{
    display: flex;
    gap: 8px;
}
.sharesa{
    display: none;
}

@media (max-width:1178px) {
    .card-detail {
        width: 90%;
    }

    .event-location {
        width: 90%;
    }

    .about-event {
        width: 45%;
    }

    .insight {
        width: 45%;
    }

    .term {
        width: 45%;
    }

}

@media (max-width:971px) {
    .card-detail {
        flex-direction: column;
        height: 100%;
    }

    .event-typo {
        align-items: center;
        padding-right: 0px;
        padding-top: 34px;
    }

    .ticket-left {
        padding-bottom: 32px;
    }

    .left {
        width: 100%;
        padding: 2px 5px;
    }

    .event-location {
        flex-direction: column;
        gap: 0px;
        padding-top: 12px;
        padding-bottom: 12px;

    }

    .addres {
        width: 90%;
        display: flex;
        gap: 8px;
        margin-left: 0;
    }

    .on-map {
        margin-right: 0;
    }

    .about-insight {
        flex-direction: column;
    }

    .term-gallery {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 64px;

    }

    .about-event {
        width: 100%;
    }

    .insight {
        width: 100%;
        height: auto;
    }

    .gallery-share {
        justify-content: center;
        width: 90%;
        align-items: center;
    }

    .gallery {
        width: 100%;
    }

    .share {
        width: 100%;
        justify-content: center;

    }

    .shares {}

    .term {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .accordion-question {
        width: 89%;
    }

    .slider-img {
        width: 90%;
        min-width: 0px !important;
        object-fit: cover;

    }

    .time-date {
        flex-wrap: wrap;
        justify-content: center;


    }

    .terms-condition {
        margin-bottom: 24px;

    }

}
@media (max-width:500px) {
    .share {
        display: none;
    }
    .sharesa{
        display: flex;
        cursor: pointer;
    }
    
}
@media (max-width:380px) {
    
    .grab-your{
        padding: 0px 17px;
    }
}
