.your-orders {
    background-color: var(--primary-black);
}

.orders-div {
    width: 75%;
    border-radius: 16px;
    border: 1px solid #FEFEFE;
    background-color: #0E1111;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;
}

.order-nav {
    display: flex;
    gap: 42px;
    margin-top: 55px;
    padding-left: 65px;
    margin-bottom: 80px;
    width: 90%;
    flex-wrap: wrap;

}

.order-nav a {
    text-decoration: none;
    color: #FFF;

    /* Body Text 1 - 20px semi-bold */
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
    /* 140% */
}

.order-nav a:hover {
    background-color: var(--primary-white);
    color: var(--primary-black);
    border-radius: 4px;
}

.active4 {
    background-color: var(--primary-white) !important;
    color: var(--primary-black) !important;
    border-radius: 4px;
}

.order-ticket {
    max-width: 956px;
    width: 95%;
    min-height: 398px;
    flex-shrink: 0;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #1F262D;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    justify-content: center;
    gap: 8px;
}

.booking-detail {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 25%;
    justify-content: center;
    align-items: center;
}

.event-image {
    width: 166px;
    height: 166px;
    border-radius: 12px;
    border: 2px solid #FEFEFE;
    background: lightgray -53.485px 0px / 149.982% 100% no-repeat;
}

.event-buttons {
    gap: 8px;
    display: flex;
    gap: 8px;
    flex-direction: column;

}

.book-detail {
    width: 153px;
    height: 94px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    padding-left: 14px;

}

.booking-id {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 184.615% */
}

.booking-date {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 184.615% */
}

.order-id {
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}


.invoice-location {
    display: flex;
    gap: 8px;

}

.invoice {
    cursor: pointer;
}

.booking-info {
    max-width: 684px;
    width: 70%;
    min-height: 329px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #FEFEFE;
    background-color: #262626;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 17px;

}

.contact-organiser {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 8px;
    width: 80%;

}

.contact-organiser button {
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 323.077% */
    background-color: #262626;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0px 20px;

}

.contact-organiser button:hover {
    background-color: var(--primary-white);
    color: var(--primary-black);
}

.event-name {
    width: 80%;
    min-height: 49px;
    flex-shrink: 0;
    border-radius: 8px;
    background-color: #EFEFEF;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
}

.book-name {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    /* 175% */
}

.book-language {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    /* 262.5% */
}

.date-location {
    width: 80%;
    height: 76px;
    flex-shrink: 0;
    border-radius: 8px;
    background-color: #EFEFEF;
    display: flex;
    margin-top: 4px;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
}

.location-event {
    color: #EFEFEF;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    display: flex;
    max-width: 309px;
    width: 100%;


}

.event-date {
    color: #FEFEFE;
    leading-trim: both;
    text-edge: cap;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    /* 210% */
    font-weight: 600;

}

.ticket-counts1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    width: 90%;
}

.ticket-type1 {
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

}

.ticket-name1 {
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    max-width: 200px;
    width: 100%;
    display: flex;
}

.ticket-number1 {
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 24px;
    /* 150% */
    max-width: 110px;
    width: 100%;
    display: flex;
}

.ticket-price1 {
    color: #EFEFEF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    max-width: 106px;
    width: 100%;
    display: flex;
}

.order-img {
    width: 100%;
    border-radius: 12px;
    height: 100%;
    object-fit: cover;

}

.total-price1 {
    width: 79%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #959595;
    margin-top: 8px;
    margin-bottom: 40px;
    padding-top: 8px;
    color: #262626;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.prix {
    color: #FEFEFE;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 137.5% */
}

.reason {
    width: 88%;
    min-height: 398px;
    flex-shrink: 0;
    border-radius: 8px;

    display: flex;
    align-items: center;
    margin-bottom: 32px;
    justify-content: center;
    gap: 12px;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup {
    height: 420px;
    background-color: #FAFAFA;
    padding: 20px;
    border-radius: 16px;
    max-width: 500px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;


}

.popup h2 {
    margin-bottom: 10px;
    margin-top: 70px;
    max-width: 323px;
    color: #0E1111;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.popup textarea {

    padding: 10px;
    margin-top: 18px;
    margin-bottom: 20px;
    max-width: 370px;
    width: 100%;
    height: 131px;
    flex-shrink: 0;
    color: #959595;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    border-radius: 16px;
    background-color: #FAFAFA;
    border: 2px solid #000;
    resize: none;
}

.popup button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #FAFAFA;
    ;
}

.popup button:last-child {
    width: 184px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    background-color: #0E1111;
    color: #FEFEFE;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 157.143% */
}

.banner {
    border: 1px solid #FEFEFE;
    background: #1F262D;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    height: 136px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.banner h3 {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    /* 262.5% */
    margin-top: 0;
    margin-bottom: 0;
}

.banner p {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    /* 210% */
    margin-top: 0;
    margin-bottom: 0;
}

.contacts {
    border-radius: 4px;
    background-color: #1F262D;
    width: 211px;
    height: 28px;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 323.077% */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 150px;
    gap: 8px;
}

.contacts:hover {
    background-color: var(--primary-white);
    color: var(--primary-black);
}

.blur {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0E1111;
    opacity: 0.9;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.order-ticket {

    position: relative;
    z-index: 1;
}

.location-order {
    color: var(--secondary-black, #262626);
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
    max-width: 241px;
    width: 100%;
    display: flex;
    gap: 8px;
}

.locationn-btn {
    cursor: pointer;
}

.black {
    display: none;
}

.lblack {
    display: none;
}

.tcover {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 18px;
}

.ttop {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.tbottom {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.topleft {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    width: 50%;

}

.topright {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 28%;

}

.bottomleft {
    display: flex;
    flex-direction: column;
    width: 55%;

}

.bottomright {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    border: 1px solid #FFF;
    max-width: 200px;
    width: 100%;
    height: 123px;
    flex-shrink: 0;
}

.event-times {
    color: #FEFEFE;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    /* 210% */
}

.qrdown,
.download {
    width: 80px;
    height: 48px;
    border: 1px solid rgba(254, 254, 254, 1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.qr-code-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: scroll;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-content3 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 3;
    border-left: 1px solid #7C92A9;
    background: #1A1F25;
    max-width: 1180px;
    width: 100%;
    /* min-height: 1196px; */
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.close-button2 {
    position: absolute;
    top: 18px;
    right: 26px;
    border-radius: 6px;
    background: #2C353E;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    color: #FEFEFE;
    flex-shrink: 0;
    color: #FEFEFE;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    cursor: pointer;
}

.qr-code {
    text-align: center;
    margin-top: 20px;
}

.qr-code img {
    width: 200px;
    /* Adjust size as needed */
    height: auto;
}

.head-close {
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    width: 90%;
    margin-bottom: 60px;
}

.tickets {
    border: 1px dashed #7C92A9;
    background: #1F262D;
    max-width: 1052px;
    width: 90%;
    min-height: 464px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    display: flex;
    gap: 46px;
}

.tickets {
    mask-image: radial-gradient(circle at 0 0, transparent 12px, black 13px)
        /* Top left circle */
        ,
        radial-gradient(circle at 0 100%, transparent 12px, black 13px);
    /* Bottom left circle */
    mask-size: 100% 50%;
    /* Split the mask into two halves vertically */
    mask-position: left top, left bottom;
    /* Position each mask appropriately */
    mask-repeat: no-repeat;
    /* Prevent the mask from repeating */
}
.ticket::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 2px solid black; /* Adjust the border properties as needed */
    z-index: 2; /* Ensure the overlay is above the QR code */
  }
  .ticket-line{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 46%, rgba(255, 255, 255, 0.00) 100%);
    width: 1px;
height: 232.054px;
  }
  .ticket-details{
display: flex;
flex-direction: column;
gap: 8px;
  }
  .tictype{
    color:  #FEFEFE;
    font-family: "Open Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 114.286% */
    max-width: 239px;
    width: 100%;
flex-shrink: 0;
display: flex;
text-align: left;
  }

  .ticno{
    color:  #FEFEFE;
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 75% */
    max-width: 176px;
    width: 100%;
height: 26px;
display: flex;

  }
  .event-detail{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: baseline;

  }
  .ticid{
    color: #FEFEFE;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 100% */
    max-width: 185px;
    width: 100%;
height: 17px;
display: flex;

  }
  .tic-event{
    color:  #EFEFEF;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    max-width: 240px;
    width: 100%;
    display: flex;
    text-align: left;
  }
.tic-lang{
    color: #EFEFEF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px; /* 262.5% */
    display: flex;

}
.tic-time{
    color:  #EFEFEF;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px; /* 210% */
    display: flex;

}
.order-qr{
    height: 256px !important;
    width:  256px !important;
}

/* pdf csss */

.pdf-ticket{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 950px;
    height: 100%;
}
.pdf-head{
    background: var(--System-Colors-black-blue, #1F262D);
    width: 950px;
height: 100px;
flex-shrink: 0;
display: flex;
justify-content: space-around;
}
.head-left{
    display: flex;
    width: 50%;
}
.logo-head{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 12px;
}
.head-titles{
    
}
.head-titles h2{
margin-top: 0;
margin-bottom: 0;
color: #FFF;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.head-titles h3{
    margin-top: 0;
    margin-bottom: 0;
    color: var(--text-secondary, #EFEFEF);
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 13.222px; /* 94.444% */
}
.head-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.head-right h3{
    margin-top: 0;
    margin-bottom: 0;
    color: var(--System-Colors-white-main, #FEFEFE);
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.head-right p{
    margin-top: 0;
    margin-bottom: 0;
    color: var(--System-Colors-white-main, #FEFEFE);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.silver-detail{
    height: 54px;
    background-color: rgba(76, 93, 107, 1);
    width: 100%;
    display: flex;

}
.silver-detail p{
    color: var(--System-Colors-white-main, #FEFEFE);
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 20px;
}
.pdf-detail{
    background: var(--System-Colors-black-blue, #1F262D);
    width: 100%;
height: 256px;
flex-shrink: 0;
display: flex;
gap: 6px;
justify-content: center;

}
.mid-line{
    background: linear-gradient(123deg, rgba(255, 255, 255, 0.00) 0%, #FFF 46%, rgba(255, 255, 255, 0.00) 100%);
    width: 1px;
height: 232.054px;
}
.pdf-left{
    display: flex;
    align-items: center;

}
.pdf-left img{
    width: 104.676px;
height: 157.565px;
flex-shrink: 0;
margin-left: 16px;
margin-right: 16px;
object-fit: cover;

}
.pdf-detailing{
    display: flex;
    flex-direction: column;
}
.pdf-detailing h1{
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.139px; /* 144.618% */
    max-width: 170px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;

}
.pdf-detailing h2{
    color:  #EFEFEF;
    font-family: "Open Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    max-width: 170px;
    width: 100%;
    line-height: 13.222px; /* 132.222% */
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;

}
.pdf-detailing h3{
    color:  #FEFEFE;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.139px; /* 192.824% */
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;

}
.pdf-detailing h4{
    color:  #FEFEFE;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.139px; 
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;

}
.pdf-detailing p{
    color:  #FEFEFE;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.139px; /* 165.278% */
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
}

@media (max-width:1100px) {
    .contact-organiser button {
        width: 211px;
        height: 28px;
        border-radius: 4px;
        background-color: #FEFEFE;
        color: #262626;
        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        /* 323.077% */
        margin-bottom: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .black {
        display: flex;
    }

    .white {
        display: none;
    }
}
.pdf-right{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: flex-start;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.pdfticketname1{
    color:  #FEFEFE;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.222px; /* 150% */
    justify-content: flex-end;
    max-width: 90px;

}
.pdfticketnumber1{
    color: #FEFEFE;
    font-family: "Open Sans";
    font-size:16px;
    font-style: normal;
    font-weight: 600;
    line-height: 13.222px; /* 150% */
    justify-content: flex-end;

}
.pdfticketprice1{
    color:  #EFEFEF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.12px; /* 137.5% */
    justify-content: flex-end;
}
.pdftickettotal1{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #959595;
    margin-top: 8px;
    margin-bottom: 40px;
    padding-top: 8px;
    color: #262626;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    width: 81%;
    justify-content: space-between;
    color:  #FEFEFE;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 13.222px; /* 150% */

}
.pdfticketprix1{
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 12.12px; /* 137.5% */
}
.pdf-right h2{
    color:  #FEFEFE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.222px; /* 82.639% */
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    width: 84%;

}
.pdf-right h3{
    color:  #FEFEFE;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.222px;
    margin-top: 0;
    margin-bottom: 0;
    width: 84%;

    text-align: left;
}
.pdf-right p{
    color: #FEFEFE;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 13.222px; /* 110.185% */
margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    width: 84%;

}
.qr-area{
    border-top: 0.551px solid #7C92A9;
background: #2C353E;
width: 100%;
min-height: 380px;
flex-shrink: 0;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.pdf-qrdetail{
    display: flex;
width: 270px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 16px;
margin-top: 24px;
margin-bottom: 24px;
}
.pdfqrinfo{
    display: flex;
    flex-direction: column;

}
.pdfqrinfo h2{
    color:  #FEFEFE;
text-align: center;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 36.96px; /* 184.8% */
margin-top: 0;
margin-bottom: 0;
}
.pdfqrinfo h3{
    color:  #FEFEFE;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 36.96px; /* 231% */
    margin-top: 0;
margin-bottom: 0;
}
.pdfqrinfo p{
    color:  #FEFEFE;
    text-align: center;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36.96px; /* 184.8% */
    margin-top: 0;
margin-bottom: 0;
}
.email-container{
    background: #2C353E; 
    height:339px
}
.email-header img{
    height:150px ;
     width:150px
}
.email-content p{
    color: #FFF;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 292px;
            width:100%;
            margin-bottom:26px;
            display: flex;
            text-align: left;
            
}
.social-icons td{
    height:40px ; 
    width:40px;
}
.tp{
    color: #FFF;
              font-family: Montserrat;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-top:26px;
}

.tp a{
    color: #ffffff; 
    text-decoration: none;
}
.righttable{
    height: 64px;
    flex-shrink: 0;
    background:  #0E1111;
    color:  #FEFEFE;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
    display: flex;
    align-items: center;
}


@media (max-width:950px) {
    .orders-div {
        border: none;
        width: 100%;
    }
}

@media (max-width:790px) {
    .lblack {
        display: flex;
    }

    .lwhite {
        display: none;
    }

    .order-ticket {
        flex-direction: column;
        padding-top: 8px;
    }

    .booking-detail {
        /* flex-direction: row; */
        width: 100%;
        margin-top: 12px;

    }

    .booking-info {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #FEFEFE;
        background: #1F262D;
        max-width: none;
    }

    .contact-organiser {
        width: 83%;
    }

    .contact-organiser button {
        width: 100%;
    }

    .event-name {
        background-color: var(--Dark-blue);
        flex-direction: column;
        align-items: baseline;

    }

    .book-name {
        color: #FEFEFE;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        /* 175% */
    }

    .book-language {
        color: #FEFEFE;
        leading-trim: both;
        text-edge: cap;
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        /* 262.5% */
    }

    .date-location {
        flex-direction: column;
        background-color: var(--Dark-blue);
        align-items: baseline;
        gap: 0px;
        height: auto;
    }

    .location-event {
        color: #EFEFEF;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        display: flex;
        max-width: 309px;
        width: 100%;
    }

    .event-date {
        color: #FEFEFE;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        /* 210% */
    }

    .stoke {
        width: 100%;
        background-color: #959595;
    }
.ticket-line{
display: none;
}
.event-detail{
    display: none;

}
.close-button2
{
    right: 52px;
    padding: 10px 20px;
}
}




@media (max-width:600px) {
    .banner h3 {
        color: #FEFEFE;
        text-align: center;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .banner {
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 150% */
    }

    .ticket-name1 {
        font-family: "Open Sans";
        font-size: 13.466px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.199px;
        /* 150% */

    }

    .ticket-number1 {
        font-family: "Open Sans";
        font-size: 13.466px;
        font-style: normal;
        font-weight: 600;
        line-height: 20.199px;
        /* 150% */
        max-width: 73px;

    }

    .ticket-price1 {
        color: #EFEFEF;
        font-family: Montserrat;
        font-size: 13.466px;
        font-style: normal;
        font-weight: 400;
        line-height: 18.516px;
        /* 137.5% */
        max-width: 73px;
    }

    .prix {
        color: #EFEFEF;
        font-family: Montserrat;
        font-size: 13.466px;
        font-style: normal;
        font-weight: 600;
        line-height: 18.516px;
        /* 137.5% */
    }
}
@media (max-width:600px) {
    .ttop{
        flex-direction: column;
        align-items: center;
    }
    .topleft{
        width: 90%;
    }
    .topright{
        width: 90%;
    }
    .tbottom{
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;

    }
    .bottomleft{
        width: 90%;
    }
    .order-qr{
        height: 188px !important;
    width: 188px !important;
    }
}
@media (max-width:420px) {
    .order-qr{
        height: 128px !important;
        width: 128px !important;
    }
    .tictype{
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
    }
    .ticno{
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
    }
    .ticid{

    }
    .tickets{
        gap: 22px;
        flex-direction: column;
    }
}