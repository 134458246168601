@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
body {
  margin: 0;
  line-height: normal;
  background-color: var(--primary-black);
}
.App{
  display: flex;
  flex-direction: column;
}
:root {


   --primary-black : #0E1111;
   --secondary-black : #262626;

   --primary-white : #FEFEFE;
   --secondary-white : #EFEFEF;
   --Dark-blue : #1F262D;
   --rare-black:  #1F262D;
   --texts: rgba(255, 255, 255, 1);
   --hint-text: #959595;
  /* fonts */
  --font-open-sans: "Open Sans";
  --font-montserrat: "Montserrat";

  /* font sizes */
  --font-size-base: 16px;

  /* Colors */

  --container-white: #fefefe;
  --system-colors-black-grey: #262626;
  --system-colors-black-blue: #1f262d;
  --system-colors-black-dark: #0e1111;
  --system-colors-light-grey: #959595;
  --text-primary: #FEFEFE

  --text-secondary: #efefef;

  /* Gaps */
  --gap-5xs: 8px;

  /* Paddings */
  --padding-6xs: 7px;
  --padding-26xl: 45px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-3xs: 10px;
  --br-base: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}