.main-notifications {
    border-radius: 16px;
    border: 1px solid #FEFEFE;
    background-color: #0E1111;
    max-width: 1149px;
    width: 90%;
    height: 100%;
    flex-shrink: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 24px;
    padding-left: 24px;
    display: flex;
justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    margin: 40px 80px 40px 80px;

}
.notification{
    border-radius: 8px;
border: 1px solid  #FEFEFE;
background-color:  var(--Dark-blue);
height: 88px;
display: flex;
justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 991px;
    width: 100%;
    flex-direction: row;

}
.notification:active{
    background-color:  #262626;

}
.notification-heading{
    color:  #FEFEFE;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 140% */
padding-left: 31PX;
margin-bottom: 4px;
margin-top: 4px;
}
.notification-text{
    color:  #FEFEFE;
    padding-left: 31PX;

font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
margin-bottom: 16px;
margin-top: 0px;
}
@media (max-width:900px) {
    .main-notifications{
        border: none;
    }
}
@media (max-width:350px) {
    .main-notifications{
        padding-left: 10px;
        padding-right: 10px;
    }
}
